import { AuthToken, Pager } from "interfaces";
import { ErrorHandler } from "./api-error";
import { isMobile } from "react-device-detect";
import { getDebugLogger } from "../../utils/debugLogger";

const debug = getDebugLogger({
	isEnabled: true,
	color: "OrangeRed",
	iconText: "OOPS:"
});

class ApiService {
	/* eslint-disable */
	private headers: Headers;
	private controller?: AbortController;
	private BASE_URL: string | undefined;

	constructor() {
		this.headers = new Headers();
		this.BASE_URL = process.env.REACT_APP_BACKEND_URI;
		this.controller =
			typeof window !== "undefined" ? new window.AbortController() : undefined;
	}

	private addAuth(): string {
		const authToken = localStorage.getItem(
			"store.solitairetripeaks.com:authToken"
		);
		const token = authToken ? JSON.parse(authToken) : null;

		if (token?.value) {
			return "Bearer " + (token as AuthToken).value;
		}

		return "";
	}

	protected async _fetch(url: string, method: string, data?: any) {
		if (!(data instanceof FormData)) {
			this.headers.set("Content-Type", "application/json");
		} else {
			this.headers.delete("Content-Type");
		}
		if (typeof window != "undefined") {
			this.headers.set("origin-path", window.location.pathname);
			this.headers.set("origin-search", window.location.search);
		}

		const syntheticId = localStorage.getItem(
			"store.solitairetripeaks.com:syntheticId"
		);

		let options: RequestInit = {
			signal: this.controller?.signal,
			method: method,
			mode: "cors",
			cache: "no-cache",
			redirect: "follow",
			referrerPolicy: "strict-origin-when-cross-origin",
			headers: {
				...this.headers,
				// prettier-ignore
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId ? JSON.parse(syntheticId) : null,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"Content-Type": "application/json",
				accept: "application/json, text/plain, */*",
				Authorization: this.addAuth()
			}
		};

		const namesPaceOverride = localStorage.getItem(
			"store.solitairetripeaks.com:namespaceOverride"
		);
		const OVERRIDE_NAME_SPACE = namesPaceOverride
			? JSON.parse(namesPaceOverride)
			: null;

		if (OVERRIDE_NAME_SPACE) {
			options = {
				...options,
				headers: {
					...options.headers,
					"X-GSN-WEBSTORE-NAMESPACE-OVERRIDE": OVERRIDE_NAME_SPACE
				}
			};
		}

		if (data) {
			(options as any).body =
				data instanceof FormData ? data : JSON.stringify(data);
		}
		if (process.env.NODE_ENV === "development") {
			console.log(
				`[${method}] ${this.BASE_URL + url} ${options.body ?? "[NO-BODY]"}`
			);
		}
		return await fetch(this.BASE_URL + url, options);
	}

	async _execute(method: string, uri: string, request?: any) {
		const record = await this._fetch(uri, method, request);

		if (record.status == 404) {
			throw new ErrorHandler("Page Not found", 404);
		}
		if (!record.ok) {
			const error = await record.json();
			//CHECK MM
			if (error.errorCode === "maintenance_mode") {
				debug.log({
					iconText: "MAINTENANCE MODE:",
					message: ["Service API, reloading"]
				});
				window.location.reload();
			}

			throw new ErrorHandler(
				error.message || error.error || record.statusText,
				record.status
			);
		}
		return await record.json();
	}

	public async Get<T>(uri: string, pager?: Pager): Promise<T> {
		const queries = this.createQuery(pager);
		return await this._execute("GET", `${uri}${queries}`);
	}

	public async Post<T>(uri: string, data?: any): Promise<T> {
		return await this._execute("POST", uri, data);
	}

	public async Put<T>(uri: string, data: any): Promise<T> {
		return await this._execute("PUT", uri, data);
	}

	public async Delete<T>(uri: string, data?: any): Promise<T> {
		return await this._execute("DELETE", uri, data);
	}

	public cancelRequest() {
		if (this.controller) {
			this.controller.abort();
		}
	}

	private createQuery(pager?: Pager) {
		if (!pager || !Object.keys(pager).length) {
			return "";
		}
		const query = Object.entries(pager)
			.map(([key, value]) => key + "=" + encodeURIComponent(value))
			.join("&");
		return `?${query}`;
	}

	/* eslint-enable */
}

const service = new ApiService();
export default service;
