import { Timer } from "./svg";
import { Fragment } from "react";
import { Bogo, BuyItAll, Offers } from "./lib";
import { useWebStoreContext } from "contexts";
import { Typography, useMediaQuery } from "@mui/material";
import CountdownV2 from "components/CountdownV2";
import { LimitedDealsIcon } from "utils/svgIcons";
import { useAppDispatch, useAppSelector } from "hooks";
import SectionHeader from "components/Section/SectionHeader";
import { onSetStickyDIPData, selectStickyDIPState } from "redux/slices";
import {
	Container,
	timerStyle,
	BgBrush,
	Image,
	timerStyleMobile
} from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import bgBrush from "assets/limited-deals/bg-brush.png";
import bgBrushMobile from "assets/limited-deals/bg-brush-mobile.png";
import { getBackgroundUrl } from "components/DIP";
import classNames from "classnames";

interface StickyDIPProps {}

const StickyDIP: React.FC<StickyDIPProps> = () => {
	const dispatch = useAppDispatch();
	const { nowTime } = useWebStoreContext();
	const { isBogo, isBuyItAll, data } = useAppSelector(selectStickyDIPState);
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const endTime = data?.offers[0].timerExpirationDate as string;
	const showCountDown = nowTime?.isBefore(endTime);
	const isLowWidth = useMediaQuery("(max-width:733px)");
	const isMobile = isLowWidth;

	const className = classNames({
		"buy-it-all": isBuyItAll
	});

	return data && showCountDown ? (
		<Fragment>
			<SectionHeader
				sx={{ marginBottom: { xs: "0px", sm: "10px" } }}
				text="LIMITED DEALS"
				icon={<LimitedDealsIcon />}
			/>
			<Container
				className={`${className} animate__animated animate__fadeInLeft`}
			>
				<Image
					className={className}
					src={getBackgroundUrl(
						data?.offers[0]?.dip?.imageUrl as string,
						isMobile ? "small" : "large"
					)}
				/>
				<BgBrush src={isMobile ? bgBrushMobile : bgBrush} />
				<CountdownV2
					icon={<Timer />}
					endTime={endTime}
					style={{
						...(isMobile ? timerStyleMobile : timerStyle),
						...(isBuyItAll && !isMobile ? { top: "7%" } : {})
					}}
					startTime={nowTime}
					text="Offer ends in: "
					labelStyle={{ color: "#EB5757", marginLeft: 6 }}
					onEnd={() => {
						dispatch(onSetStickyDIPData(undefined));
					}}
				/>
				{showOfferId && (
					<Typography
						sx={{
							fontWeight: 700,
							color: "red",
							fontSize: "12px",
							height: "auto",
							position: "absolute",
							top: 50,
							left: "1.1%"
						}}
					>
						{data?.offers?.[0]?.dip?.id}
					</Typography>
				)}
				{isBogo ? <Bogo /> : isBuyItAll ? <BuyItAll /> : <Offers />}
				{/* <BgBottomBrush /> */}
			</Container>
		</Fragment>
	) : (
		<Fragment />
	);
};

export default StickyDIP;
