import { SxProps } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section`
	position: relative;
	margin-bottom: 6rem;
`;

export const TitleMobile = styled.img`
	position: absolute;
	top: 8%;
	left: 50%;
	transform: translateX(-50%);
	width: 400px;
	@media (max-width: 650px) {
		width: 230px;
	}
`;

export const Avatar = styled.img`
	position: absolute;
	top: 15%;
	left: 50%;
	transform: translateX(-50%);
	width: 450px;
`;

export const Background = styled.div<{ bgImg: string }>`
	position: absolute;
	width: 100%;
	height: 471px;
	background-image: url(${(props) => props.bgImg});
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	display: block;
	z-index: -1;

	@media (orientation: landscape) {
		@media (max-width: 899px) {
			background-size: 150% 100%;
		}
	}

	@media (min-width: 768px) {
		height: 394px;
	}
	@media (min-width: 1536px) {
		height: 668px;
	}
`;

export const PoisBountyWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 220px;

	@media (min-width: 820px) {
		margin-top: 240px;
	}
	@media (min-width: 1280px) {
		margin-top: 220px;
	}
	@media (min-width: 1536px) {
		margin-top: 400px;
	}
`;

export const sxStyles: SxProps = {
	width: { xs: "370px", sm: "95vw", md: "100vw" },
	maxWidth: "875px",
	boxShadow: "0px 15px 15px rgba(0, 0, 0, 0.1)",
	marginTop: "1rem",
	borderRadius: "18px",
	".pois-bounty-widget": { maxWidth: "none" }
};

export const Box1 = styled.div`
	width: 370px;
	@media (min-width: 650px) {
		width: 95vw;
	}

	@media (min-width: 900px) {
		width: 100vw;
	}

	@media (max-width: 380px) {
		width: 98vw;
	}

	max-width: 875px;
	box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
	margin-top: 1rem;
	border-radius: 18px;
	[class*="pois-bounty-widget"] {
		max-width: none;
	}
`;

/***************************************************
 * V2 styles
 ***************************************************/

export const TitleV2 = styled.img`
	position: absolute;
	top: 10%;
	left: 30%;
	transform: translateX(-20%);
	width: 391px;

	@media (max-width: 1024px) {
		left: 20%;
		top: 15%;
	}
	@media (max-width: 1366px) and (max-height: 820px) {
		width: 300px;
		top: 13%;
	}
	@media (max-width: 820px) {
		width: 300px;
	}
`;

export const TitleMobileV2 = styled.img`
	position: absolute;
	top: 7%;
	left: 5%;
	width: 400px;

	@media (max-width: 650px) {
		width: 225px;
	}
`;
