import {
	ProfileImageComponent,
	UserLevelComponent,
	DryRunOverrideLabel,
	LoginButtons,
	UserInfo,
	GenericBalance
} from "./lib";
import { WsDialogs } from "interfaces";
import coinIcon from "assets/coin.png";
import { Box, Grid } from "@mui/material";
import { CrownIcon } from "utils/svgIcons";
import { useGoogleAnalyticsContext } from "contexts";
import { User, UserProfileContainer } from "./styled";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

const UserProfile = (): JSX.Element => {
	const { userTracker, authToken } = useWebStoreContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { openDialog } = useDialogModalRedux();
	const inventory = userTracker?.inventory;

	const handleClickLoyaltyPoints = () => {
		openDialog(WsDialogs.LOYALTY_FAQ);
		reportEvent("loyalty_points_click", { source: "right_menu" });
		reportEvent("dialog_shown", { source: "loyalty_faq_dialog" });
	};

	return (
		<Box
			sx={{
				width: "100%",
				marginTop: "40px"
			}}
		>
			{userTracker && authToken ? (
				<UserProfileContainer>
					<User>
						<ProfileImageComponent />
						<UserInfo />
					</User>
					<UserLevelComponent />
					<Grid container spacing={0.6} alignItems="center">
						<Grid item xs={12} sm={6} md={11}>
							<GenericBalance text="Coins" icon={<img src={coinIcon} alt="" />} points={inventory?.coins ?? 0} />
						</Grid>
						<Grid item xs={12} sm={6} md={11}>
							<GenericBalance
								text="Loyalty Points"
								icon={<CrownIcon fill="#113B62" />}
								points={userTracker.loyaltyPoints}
								onClick={handleClickLoyaltyPoints}
							/>
						</Grid>
					</Grid>
					<DryRunOverrideLabel />
				</UserProfileContainer>
			) : (
				<LoginButtons />
			)}
		</Box>
	);
};

export default UserProfile;
