import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography
} from "@mui/material";
import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts";
import FacebookLoginButton from "components/Buttons/FacebookLoginButton";
import ScopelyLoginButton from "components/Buttons/ScopelyLoginButton";
import CloseIconButton from "../lib/CloseIconButton";

const DialogLoginToContinue = ({
	show,
	onCloseClick
}: {
	show: boolean;
	// eslint-disable-next-line
	onCloseClick: (event?: any, reason?: any) => void;
}) => {
	const { cdnPrefix, isFeatureFlagEnabled } = useEnvContext();
	const backgroundImage = `url(${cdnPrefix}/spec_modal_login/header_modal_login.png)`;

	return (
		<Dialog
			open={show}
			onClose={onCloseClick}
			sx={{
				".MuiPaper-root": {
					borderRadius: 6,
					paddingBottom: ".15rem",
					width: "462px"
				}
			}}
		>
			<DialogTitle
				sx={{
					height: "232px",
					backgroundColor: "SteelBlue",
					backgroundImage: backgroundImage,
					backgroundPosition: "center",
					backgroundSize: "auto 100%",
					overflow: "hidden"
				}}
			>
				<CloseIconButton onClose={onCloseClick} />
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: 0 }}>
				{/* padding-left: 2vw; padding-right: 2vw; */}
				<DialogContentText
					sx={{
						padding: {
							xs: ".75rem 2vw",
							sm: ".75rem 1rem"
						}
					}}
				>
					<Typography
						variant="h5"
						component="div"
						sx={{
							fontWeight: "bold",
							margin: ".65rem 0 .5rem",
							display: "block",
							textAlign: "left",
							// fontSize: "130%",
							lineHeight: 2,
							color: "#333333",
							fontSize: { xs: "4vw", sm: "1.5rem" }
						}}
					>
						<span>Login to continue</span>
					</Typography>
					<Typography
						variant="body1"
						component="strong"
						sx={{
							// fontWeight: "bold",
							margin: ".15rem 0 .5rem",
							display: "block",
							textAlign: "left",
							// fontSize: "130%",
							lineHeight: 1.3,
							color: "#333333",
							fontSize: { xs: "3.7vw", sm: "1rem" }
						}}
					>
						To use the Web Shop, your <b>Tiki Solitaire TriPeaks</b> account
						must be connected to your Scopely or Facebook account.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
				<Box
					sx={{
						paddingTop: ".75rem",
						paddingBottom: ".75rem",
						marginBottom: 1,
						width: "calc(100% - 6rem)",
						marginLeft: 2,
						marginRight: 2
					}}
				>
					{isFeatureFlagEnabled(FeaturesFlags.SCOPELY_LOGIN) && (
						<ScopelyLoginButton source={"login_to_continue_dialog"} />
					)}
					<FacebookLoginButton
						cbClick={async () => {
							onCloseClick({ keepCurrentOffer: true });
						}}
						source={"login_to_continue_dialog"}
					/>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export { DialogLoginToContinue };
