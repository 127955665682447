import ThemeButton from "components/Buttons/ThemeButton";
import styled, { CSSProperties } from "styled-components";

export const DripOfferContainer = styled.div<{ largeTicket: string }>`
	h3 {
		color: #fff;
		font-family: "Baloo";
		font-size: 20px;
		margin: 0;
		text-shadow: 1px 2px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
			1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
			1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000;

		&.mobile {
			color: #5d2904;
			text-align: center;
			font-size: 16px;
			line-height: 16px;
		}

		&.isPopup {
			@media (orientation: portrait) {
				color: #5d2904;
				text-shadow: none;
			}
		}

		@media (max-width: 599px) {
			font-size: 16px;
			text-shadow: none;
		}
		@media (max-width: 430px) {
			font-size: 13px;
		}
	}

	.ticket {
		background-image: url(${(props) => props.largeTicket});
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		padding: 15px 22px;

		.item-background {
			position: absolute;
			border-radius: 16px;
			opacity: 0.2;
			background: #d98883;
			width: 100%;
			height: 100%;
		}

		@media (max-width: 912px) and (min-height: 753px) {
			padding: 15px 15px;
		}
		@media (max-width: 430px) {
			padding: 12px 22px;
		}
		@media (orientation: landscape) {
			@media (max-width: 896px) {
				padding: 10px 15px;
			}
		}
	}

	.item-content {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		&.small {
			height: 103px;

			@media (orientation: landscape) {
				@media (max-width: 896px) {
					height: 90px;
				}
				@media (max-width: 667px) {
					height: 82px;
				}
			}

			img {
				height: 50%;
			}
		}

		&.large {
			height: 70px;

			@media (orientation: landscape) {
				@media (max-width: 896px) {
					height: 67px;
				}
				@media (max-width: 667px) {
					height: 59px;
				}
			}

			img {
				max-height: 75%;
				max-width: 85%;
			}
		}

		p {
			position: absolute;
			right: 0;
			color: #fff;
			font-family: "Baloo";
			font-size: 20px;
			line-height: 20px;
			margin: 0;
			text-shadow: 1px 2px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
				1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
				1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000;

			&.small {
				bottom: 15%;
			}

			&.large {
				bottom: 10%;
				right: 0;
			}

			&.is-coin {
				right: 50%;
				transform: translateX(50%);
			}

			@media (orientation: landscape) {
				@media (max-width: 896px) {
					font-size: 16px;
				}
			}
			@media (orientation: portrait) {
				@media (max-width: 912px) {
					font-size: 16px;
				}
				@media (max-width: 430px) {
					font-size: 20px;
				}
			}
		}
	}

	.plus {
		font-family: "Baloo";
		font-size: 44px;
		color: white;
		text-shadow: 1px 3px 0 #000, -3px 3px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000, 0px 3px 0 #000, 0px -3px 0 #000, -3px 0px 0 #000,
			3px 0px 0 #000, 3px 3px 0 #000, -3px 1px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000, 0px 3px 0 #000, 0px -3px 0 #000, -3px 0px 0 #000,
			3px 0px 0 #000, 3px 3px 0 #000, -3px 3px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000, 3px 3px 0 #000, -3px 1px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000;

		@media (orientation: landscape) {
			@media (max-width: 896px) {
				font-size: 40px;
				text-shadow: 1px 2px 0 #000, -2px 2px 0 #000, 2px -2px 0 #000,
					-2px -2px 0 #000, 0px 2px 0 #000, 0px -2px 0 #000, -2px 0px 0 #000,
					2px 0px 0 #000, 2px 2px 0 #000, -2px 1px 0 #000, 2px -2px 0 #000,
					-2px -2px 0 #000, 0px 2px 0 #000, 0px -2px 0 #000, -2px 0px 0 #000,
					2px 0px 0 #000, 2px 2px 0 #000, -2px 2px 0 #000, 2px -2px 0 #000,
					-2px -2px 0 #000, 2px 2px 0 #000, -2px 1px 0 #000, 2px -2px 0 #000,
					-2px -2px 0 #000;
			}
			@media (max-width: 734px) {
				font-size: 30px;
			}
		}
		@media (orientation: portrait) {
			@media (max-width: 912px) {
				font-size: 40px;
			}
		}
	}

	.sale-info {
		height: 100%;
		background-image: url(${(props) => props.largeTicket});
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		padding: 46px 17px 12px;

		p {
			text-align: center;
			color: #f8f3e4;
			font-family: "Baloo";
			font-size: 16px;
			line-height: 16px;
			margin: 0;

			&.title {
				color: #5d2904;
				text-align: left;
				line-height: 79%;
			}
		}

		span {
			padding: 0 12px;

			@media (max-width: 599px) {
				padding: 0 10px;
			}
		}

		.red-x {
			width: 24px;
			height: 23px;
		}

		@media (orientation: landscape) {
			@media (max-width: 734px) {
				padding: 42px 17px 10px;
			}
		}
	}
`;

export const ContainerScaled = styled("div")<{ scale: number }>`
	width: 954px;
	max-width: 90vw;
	background-color: #fff;
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
	transform: scale(${(props) => props?.scale});

	@media (orientation: landscape) {
		@media (max-width: 734px) {
			max-width: 98vw;
			width: 98vw;
		}
	}
`;

export const Background = styled.div<{ background: string }>`
	background-image: url(${(props) => props.background});
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: center center;

	@media (max-width: 599px) {
		background-size: 100% 120%;
		background-position: top center;
	}
`;

export const Title = styled.img`
	max-width: 330px;
	width: 100%;

	@media (max-width: 599px) {
		padding-top: 40px;
		max-width: 184px;
	}

	@media (max-width: 430px) {
		width: 175px;
	}

	@media (orientation: landscape) {
		@media (max-width: 932px) {
			max-width: 184px;
		}
	}
`;

export const timerStyle: CSSProperties = {
	position: "absolute",
	top: 8,
	left: 8
};

export const Button = styled(ThemeButton)`
	max-width: 329px;
	width: 100%;
	font-size: 24px;

	@media (orientation: portrait) {
		@media (max-width: 599px) {
			font-size: 20px;
		}
	}
`;
