import styled, { CSSProperties } from "styled-components";
import bgBrushTop from "../../../../assets/waterfall-sale/bg-brush-top.png";
import bgBrushBottom from "../../../../assets/waterfall-sale/bg-brush-bottom.png";

interface IDiv {
	BackgroundImage: string;
}

export const Container = styled.div<IDiv>`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	background-image: url(${(props) => props.BackgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 1040px;

	@media (min-width: 731px) {
		background-size: auto auto;
		margin: 0px 0px 5px;
		height: 450px;
	}

	@media (min-width: 930px) {
		height: 550px;
	}
`;

export const OffersContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 1;
	@media (min-width: 731px) {
		flex-direction: row;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	@media (min-width: 731px) {
		flex-direction: row;
	}
`;

export const Image = styled.img`
	width: 28px;
	margin-top: 18px;
	transform: rotate(90deg);
	@media (min-width: 731px) {
		margin-top: 40px;
		transform: rotate(0deg);
	}
	@media (min-width: 930px) {
		width: 40px;
	}
`;

export const BgTopBrush = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: auto auto;
	background-position: top;
	background-repeat: no-repeat;
	background-image: url(${bgBrushTop});
	padding: 2px 0;
`;

export const BgBottomBrush = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: auto auto;
	background-position: bottom;
	background-repeat: no-repeat;
	background-image: url(${bgBrushBottom});
`;

export const Title = styled.div<IDiv>`
	content: url(${(props) => props.BackgroundImage});
	max-width: 300px;
	margin-top: 20px;

	@media (min-width: 731px) {
		max-width: 380px;
		margin-top: 50px;
	}

	@media (min-width: 931px) {
		max-width: 420px;
	}
`;

export const timerStyle: CSSProperties = {
	whiteSpace: "nowrap"
};
