import { SxProps, styled } from "@mui/material";
import { ReactComponent as CircleIcon } from "../../assets/Circle.svg";

const Header = styled("h2")`
	text-align: left;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	text-transform: uppercase;
	color: #113b62;
	font-family: Baloo;
	font-size: 34px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;

	svg {
		transform: translateY(-0.1rem);
		fill: #063774;
		width: 25px;
		height: auto;
	}
`;

const SectionHeader = ({
	text,
	icon,
	sx
}: {
	text: string;
	icon: JSX.Element;
	sx?: SxProps;
}): JSX.Element => (
	<Header sx={sx}>
		{icon ? icon : <CircleIcon />}
		{text}
	</Header>
);

export default SectionHeader;
