import { Container, Grid, useMediaQuery } from "@mui/material";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";
import CountdownV2 from "components/CountdownV2";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import {
	useEnvContext,
	useGoogleAnalyticsContext,
	useWebStoreContext
} from "contexts";
import { IShopReward, IShopRewardItem } from "contexts/WebStoreProvider/types";
import { FeaturesFlags, getCount, getDisplay } from "helpers";
import { useAppDispatch } from "hooks";
import useBreakpoints from "hooks/useBreakpoints";
import { Config, PurchaseOfferSingleItem, WsDialogs } from "interfaces";
import { DripOfferResponse, Node } from "interfaces/drip-offer";
import {
	getAvailablePrizes,
	getNodeItemData,
	handleImageVariant,
	handleTicketVariant,
	handleTitleImageVariant
} from "pages/DripOffer/hooks";
import {
	DisplayFirstNode,
	DisplayNode,
	Offer,
	SaleInfo
} from "pages/DripOffer/lib";
import { InfoButton } from "pages/DripOffer/styles";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	featureTrackerAPI,
	useClaimDripOfferRewardMutation,
	useGetFeatureTrackerQuery,
	useGetOffersQuery
} from "redux/apis";
import { onClosePopUp, onOpenPopUp } from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { InfoIcon } from "utils/svgIcons";
import {
	Background,
	Button,
	ContainerScaled,
	DripOfferContainer,
	Title,
	timerStyle
} from "./styles";

export const DripOffer = () => {
	const scale = useCalculateScale(378, 380);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const {
		authToken,
		handleErrorResponse,
		nowTime,
		postUserMetadata,
		store,
		suppressOrderConfirmation,
		userTracker,
		wsStoreBuy
	} = useWebStoreContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { openDialog } = useDialogModalRedux();
	const { isSmDown } = useBreakpoints();
	const popupRef = useRef<HTMLDivElement>(null);

	const isPortrait = useMediaQuery("(orientation: portrait)");
	const isExtraSmall = useMediaQuery("(max-width: 360px)");
	const isLandscapeExtraSmall = useMediaQuery("(max-width: 760px)");

	const [ClaimDripOfferReward, { isLoading: IsClaimLoading }] =
		useClaimDripOfferRewardMutation();

	const { data, refetch, isSuccess, isFetching, isError } =
		useGetFeatureTrackerQuery(
			{
				FeatureType: "drip-offer",
				isAuthenticated: !!userTracker
			},
			{
				skip: !isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER) || !userTracker,
				refetchOnFocus: true
			}
		);

	const { data: purchaseOffers } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: authToken?.value ? true : false
	});

	const [firstOffer, setFirstOffer] = useState<{
		offer: PurchaseOfferSingleItem;
		items: IShopRewardItem[];
	}>();
	const [availableNode, setAvailableNode] = useState<Node>();

	const { tracker }: DripOfferResponse = data || {};

	const handleScrollToElement = () => {
		if (popupRef.current) {
			popupRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center"
			});
		}
	};

	const onClose = () => {
		dispatch(onClosePopUp());
	};

	const onOpenInfoPopup = () => {
		navigate("/special-offers");
		dispatch(onOpenPopUp("drip-offer-info"));
	};

	const handleLargeTicketVariant = (url: string) => {
		return url.replace("{drip_offer_variant}", "small");
	};

	const handleEnd = () => {
		setTimeout(() => {
			onClose();
		}, 1000);
	};

	const invalidateFeatureTracker = () => {
		dispatch(
			featureTrackerAPI.util.invalidateTags([
				{
					type: "featureTrackerTag",
					id: "drip-offer"
				}
			])
		);
	};

	const onClickBuy = async (offer?: PurchaseOfferSingleItem, node?: Node) => {
		if (!authToken?.value) {
			return;
		}

		if (tracker.offerPurchaseDate && node) {
			await ClaimDripOfferReward({
				eventId: node.eventId,
				nodeIndex: node.nodeIndex
			})
				.unwrap()
				.then(() => {
					if (getAvailablePrizes(tracker.nodes) === 1) {
						dispatch(onOpenPopUp("drip-offer-end"));
					} else {
						dispatch(onOpenPopUp("drip-offer-reward"));
					}
					navigate("/special-offers");
					refetch();
					setAvailableNode(undefined);
					if (store) {
						const timeRemaining = getCount(nowTime, tracker.claimEndDate);
						const timeToDisplay = getDisplay(timeRemaining);
						const rewardType: IShopRewardItem[] | null = getNodeItemData(
							store,
							node
						);
						reportEvent("drip_offer", {
							source: "claim_free_daily_reward",
							reward_id: node.rewardId,
							reward_type: `${rewardType?.[0].id}_${rewardType?.[0].value}`,
							location: "special_offer_section",
							time_to_claim: timeToDisplay
						});
					}
				})
				.catch(() => {
					const response = {
						data: {
							errorCode: "The prize has already been claimed!",
							errorMessage: ""
						}
					}
					handleErrorResponse({ response: response });
					invalidateFeatureTracker();
				});
		} else if (!tracker.offerPurchaseDate && offer) {
			const { id, price, rewardId } = offer;

			wsStoreBuy({ offerId: id }).then((invoice) => {
				if (invoice) {
					openDialog(WsDialogs.XSOLLA_PURCHASE, {
						xsollaToken: invoice.xsollaToken,
						xsollaHost: invoice.xsollaHost,
						suppressOrderConfirmation,
						source: "drip-offer",
						postUserMetadata,
						onPurchaseStarted: (data) => {
							reportEvent("purchase_started", {
								section_name: "special_offer_popup",
								offer_id: id,
								price_in_cents: price * 100,
								reward_id: rewardId,
								invoice_id: invoice?.id,
								paystation_id: data?.payStation
							});
						},
						onPurchaseSuccess: () => {
							reportEvent("purchase_success", {
								invoice_id: invoice?.id
							});
							refetch();
							dispatch(onOpenPopUp("drip-offer-reward"));
							navigate("/special-offers");
						},
						cbClose: () => {
							reportEvent("dialog_x_closed", {
								source: "xsolla_dialog",
								page_location: location.pathname
							});
						}
					});
				}
			});
		}
	};

	useEffect(() => {
		handleScrollToElement();
	}, [isSmDown]);

	useEffect(() => {
		if ((isSuccess && !data?.tracker) || isError) {
			dispatch(onClosePopUp());
		}
	}, [data, isSuccess, isError]);

	useEffect(() => {
		if (tracker && purchaseOffers) {
			handleScrollToElement();
			const offer: PurchaseOfferSingleItem = purchaseOffers.offers.find(
				(offer: Config) => offer.id === tracker.offerId
			);
			if (offer) {
				if (store) {
					const reward = store.rewards[offer.rewardId];
					if (!reward) {
						console.error("reward", reward);
						return;
					}
					const items: IShopRewardItem[] = Object.keys(reward.items).map(
						(key) => {
							const { imageUrl = "" } = {
								...store.rewardTypes.filter((t: IShopReward) => t.id === key)[0]
							};
							const rewardItem = reward.items;
							return {
								id: key,
								value: rewardItem[key as keyof typeof rewardItem],
								imageUrl
							};
						}
					);
					setFirstOffer({ offer: offer, items: items });
				}
			}
		}
	}, [purchaseOffers, tracker]);

	if (tracker && Object.keys(tracker).length > 0 && firstOffer) {
		const {
			claimEndDate,
			dateTimes,
			isFinished,
			nodes,
			offerAvailableDays,
			offerBackgroundImageUrl,
			offerEndDate,
			offerPurchaseDate,
			offerTicketImageUrl,
			saleInfoImageUrl,
			titleImageUrl
		} = tracker;
		return (
			<ContainerScaled scale={scale} ref={popupRef}>
				<CloseIconButton onClose={onClose} />
				<DripOfferContainer
					largeTicket={handleLargeTicketVariant(
						offerPurchaseDate ? saleInfoImageUrl : offerTicketImageUrl
					)}
				>
					<Background
						background={handleImageVariant(
							offerBackgroundImageUrl,
							isPortrait && isSmDown
						)}
					>
						<Container sx={{ px: { xs: isExtraSmall ? 3 : 5, sm: 3 } }}>
							<CountdownV2
								endTime={claimEndDate ?? offerEndDate}
								style={timerStyle}
								startTime={nowTime}
								text={claimEndDate ? "Time to claim: " : "Offer ends in: "}
								labelStyle={{ color: "#fff", marginLeft: 6 }}
								onEnd={() => {
									handleEnd();
								}}
							/>
							<Grid item xs={12} textAlign="center" py={1} position="relative">
								{isSmDown && !offerPurchaseDate && (
									<InfoButton
										className="is-small"
										aria-label="open info popup"
										onClick={onOpenInfoPopup}
									>
										<InfoIcon />
									</InfoButton>
								)}
								<Title
									src={handleTitleImageVariant(
										titleImageUrl,
										!!offerPurchaseDate
									)}
									alt="Tiki special offer"
								/>
								{offerPurchaseDate && !isSmDown && (
									<Grid
										item
										position="absolute"
										top={{ sm: 8, md: 10 }}
										right={{ sm: isLandscapeExtraSmall ? 0 : 20, md: 5 }}
										width={{ xs: 192, sm: 180, md: 192 }}
										height={{ xs: 135, sm: 123, md: 135 }}
									>
										<SaleInfo
											offerAvailableDays={offerAvailableDays}
											nodes={nodes}
											localTime={dateTimes.local}
											isPopup={true}
											nowTime={nowTime}
											endTime={claimEndDate}
										/>
									</Grid>
								)}
							</Grid>
							<Grid item>
								<Grid
									container
									direction="row"
									spacing={{ xs: 1, sm: 0.5, md: 1 }}
								>
									{!offerPurchaseDate && (
										<Grid item xs={12} sm={4}>
											<Offer firstOffer={firstOffer} isPopup={true} />
										</Grid>
									)}
									<Grid
										item
										xs={12}
										sm={offerPurchaseDate ? 10 : 8}
										md={offerPurchaseDate ? 9 : 8}
										mx={{ sm: "auto" }}
									>
										<Grid
											item
											xs={12}
											sm={12}
											position="relative"
											height={31.5}
										>
											{!isSmDown && (
												<InfoButton
													className="is-popup"
													aria-label="open info popup"
													onClick={onOpenInfoPopup}
												>
													<InfoIcon />
												</InfoButton>
											)}
											{!offerPurchaseDate && (
												<h3 className="isPopup">
													Claim a new prize each day you login:
												</h3>
											)}
										</Grid>
										<Grid item sm={12} mx="auto">
											<Grid
												container
												direction="row"
												spacing={{ xs: 1, sm: 0.5, md: 1 }}
												mb={2}
												justifyContent="center"
											>
												{firstOffer && offerPurchaseDate && (
													<DisplayFirstNode
														items={firstOffer.items}
														background={handleTicketVariant(
															nodes[0].rewardBackgroundImageUrl,
															false
														)}
														isPopup={true}
													/>
												)}
												{nodes.map((node) => (
													<DisplayNode
														key={node.rewardId}
														node={node}
														onSetAvailableNode={(n: Node) =>
															!isFetching && setAvailableNode(n)
														}
														changeSize={!!offerPurchaseDate}
														availableNode={availableNode}
														isPopup={true}
													/>
												))}
												{offerPurchaseDate && isSmDown && (
													<SaleInfo
														offerAvailableDays={offerAvailableDays}
														nodes={nodes}
														localTime={dateTimes.local}
														nowTime={nowTime}
														endTime={claimEndDate}
													/>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									pb={2}
									display="flex"
									justifyContent="center"
								>
									{!isFinished && (
										<Button
											disabled={
												(offerPurchaseDate && !availableNode) ||
												(availableNode &&
													availableNode.state !== "available") ||
												IsClaimLoading
											}
											onClick={() =>
												onClickBuy(firstOffer?.offer, availableNode)
											}
										>
											{offerPurchaseDate
												? availableNode && availableNode?.state === "available"
													? "COLLECT"
													: "Come Back Tomorrow"
												: `${firstOffer?.offer.price} USD`}
										</Button>
									)}
								</Grid>
							</Grid>
						</Container>
					</Background>
				</DripOfferContainer>
			</ContainerScaled>
		);
	} else {
		return null;
	}
};
