/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useExecuteActionMutation } from "redux/apis/debugAction";

const useDebugAction = () => {
	const [searchParams] = useSearchParams();
	const [executeAction, { isLoading, isError, isSuccess }] =
		useExecuteActionMutation();

	const setAction = async () => {
		const value = {
			configId: searchParams.get("debugactionid")
		};

		if (value.configId) {
			//console.log("test_05", value.debugactionid);
			await executeAction(value).unwrap();
		}
	};

	useEffect(() => {
		setAction();
	}, [searchParams]);

	return { isLoading, isError, isSuccess };
};

export default useDebugAction;
