import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";

const ConfirmDialog = ({
	buttonText,
	onClick
}: {
	buttonText: string;
	onClick: () => void;
}) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div>
			<Button variant="outlined" onClick={handleOpen}>
				{buttonText}
			</Button>
			<Dialog fullWidth open={open} onClose={handleClose}>
				<DialogTitle>{buttonText}</DialogTitle>
				<DialogContent>Are you sure?</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button
						onClick={() => {
							onClick();
							handleClose();
						}}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ConfirmDialog;
