import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import { useGoogleAnalyticsContext } from "contexts";
import { useAppDispatch } from "hooks";
import { useEffect } from "react";
import { featureTrackerAPI } from "redux/apis";
import { onClosePopUp } from "redux/slices";
import {
	Body,
	Container,
	Text,
	Title
} from "./styles";

interface DripOfferInfoProps { }

export const DripOfferInfo: React.FC<DripOfferInfoProps> = () => {
	const dispatch = useAppDispatch();
	const scale = useCalculateScale(378, 380);
	const { reportEvent } = useGoogleAnalyticsContext();

	const onClose = () => {
		dispatch(
			featureTrackerAPI.util.invalidateTags([
				{
					type: "featureTrackerTag",
					id: "endless-offer"
				}
			])
		);
		dispatch(onClosePopUp());
	};

	useEffect(() => {
		reportEvent("drip_offer", {
			source: "info_button_click",
		});
	}, []);

	return (
		<Container scale={scale}>
			<CloseIconButton onClose={onClose} />
			<Body>
				<Title>
					Login Everyday
				</Title>
				<Text>
					To claim the rest of your prizes, login every day for a new prize from this offer.
				</Text>
				<Title>
					10 Days to Claim All Rewards
				</Title>
				<Text>
					To receive all prizes, you have 10 consecutive days to claim all prizes from date of purchase.
				</Text>
			</Body>
		</Container>
	);
};
