import { DipButton } from "interfaces";
import { DisplayReward } from "../types";

export const getFlexBasis = (
	data: DipButton[],
	rewards: DisplayReward[],
	isMobile: boolean
): number => {
	let flexBasis: number = 100 / 4;
	if (rewards.length <= 6) {
		flexBasis = 100 / 3;
		if (rewards.length <= 4) {
			flexBasis = 100 / rewards.length;
		}
	}
	if (data?.length === 1) {
		if (isMobile && rewards.length <= 4) {
			flexBasis = 100 / 2;
		}
		if (!isMobile) {
			flexBasis = 100 / rewards.length;
		}
	}

	if (data?.length === 3) {
		if (isMobile && rewards.length <= 6) {
			flexBasis = 100 / rewards.length;
		}
	}

	return flexBasis;
};
