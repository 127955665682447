import styled from "styled-components";
import { Typography } from "@mui/material";
import ThemeButton from "components/Buttons/ThemeButton";
import { expandOverlay, moveDiagonally } from "./animations";

export const BuyItAllStyled = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 0 16px;
	position: absolute;
	bottom: 5%;
	right: 0px;
	left: 0px;

	@media (orientation: portrait) {
		justify-content: flex-end;
		gap: 0.5rem;
		bottom: 3%;

		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	@media (orientation: landscape) {
		gap: 0.5rem;
	}
`;

export const LockedOffer = styled.div`
	position: absolute;
	width: calc(100% - 8px);
	height: calc(100% - 8px);
	background: rgba(0, 0, 0, 0.4);
	border-radius: 12px;
	z-index: 1;
	animation: ${expandOverlay} 0.2s forwards;
`;

export const FingerPointer = styled.img`
	width: 65px;
	position: absolute;
	bottom: -85%;
	right: -20%;
	z-index: 2;
	animation: ${moveDiagonally} 2s infinite;

	@media (orientation: portrait) {
		@media (max-width: 820px) {
			bottom: -45px;
			right: -20%;
			width: 45px;
		}
	}
	@media (orientation: landscape) {
		@media (max-width: 932px) {
			right: -35%;
		}
		@media (max-width: 896px) {
			bottom: -50px;
			right: -20%;
		}
	}
`;

// prettier-ignore
export const BuyItAllItem = styled.div<{ tier: number }>(({ tier }) => `
	position: relative;
	background-color: rgba(51, 51, 51, 0.3);
	border: 4px solid ${
		(tier === 0 && "#C9832E") ||
		(tier === 1 && "#D9D9D9") ||
		(tier === 2 && "#FBBD05")
	};
	border-radius: 12px;
	padding: 0.5rem;
	height: calc((${tier} * 22px) + "auto");
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	@media (max-width: 390px) {
		padding: 0.5rem 0.25rem;
	}
`);

// prettier-ignore
export const Items = styled.div<{ tier: number }>(({ tier }) => `
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media (orientation: portrait) {
		@media (min-width: 734px) {
			margin: calc((${tier} * 6px));
		}
		@media (max-width: 733px) {
			flex-direction: column;
			gap: calc((${tier} * 5px));
		}

		@media (max-width: 390px) {
			gap: calc((${tier} * 3px));
		}
	}
	@media (orientation: landscape) {
		@media (min-width: 820px) {
			margin: calc((${tier} * 11px));
	
			@media (max-height: 430px) {
				margin: calc((${tier} * 6px));
			}
		}
		@media (max-width: 820px) {
			margin: calc((${tier} * 5px));

			@media (max-width: 667px) and (max-height: 375px) {
				flex-direction: column;
				gap: calc((${tier} * 11px));
			}
		}
	}
`);

// prettier-ignore
export const RewardItem = styled.div<{ width: string }>(({ width }) => `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${width};
	box-sizing: border-box;
	padding: 1px;
`);

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 15px;
`;

export const ItemImage = styled.img`
	width: 42px;
	height: 42px;
	object-fit: contain;
	justify-self: center;
	align-self: center;

	@media (orientation: portrait) {
		@media (max-width: 414px) {
			width: 35px;
			height: 35px;
		}
	}

	@media (orientation: landscape) {
		width: 50px;
		height: 50px;

		@media (max-height: 430px) {
			width: 40px;
			height: 40px;
		}
		@media (max-height: 390px) {
			width: 35px;
			height: 35px;
		}
	}
`;

export const Footer = styled.div`
	button {
		width: 100%;
		padding: 0.4rem 0.2rem;
	}
	&.loyalty-reward {
		grid-template-columns: minmax(85px, auto) auto;
		grid-gap: 10px;
	}
`;

export const LoyaltyPoints = styled.div`
	background-color: rgba(0, 0, 0, 0.51);
	color: #fff;
	font-size: 0.9rem;
	font-weight: 700;
	padding: 0.5rem 0.8rem;
	margin: 0.3rem 0rem;
	border-radius: 100px;
	line-height: 22px;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.2rem;
	}

	@media (orientation: portrait) {
		@media (max-width: 520px) {
			font-size: 0.75rem;
		}
		@media (max-width: 430px) {
			padding: 0.2rem 0.5rem;

			svg {
				width: 0.75rem;
			}
		}
		@media (max-width: 414px) {
			font-size: 0.6rem;

			svg {
				width: 0.6rem;
			}
		}
	}
	@media (orientation: landscape) {
		@media (max-width: 932px) {
			font-size: 0.7rem;
		}
		@media (max-height: 390px) {
			font-size: 0.7rem;
			padding: 0.2rem 0.5rem;

			svg {
				width: 0.7rem;
			}
		}
	}
`;

export const BuyItAllFooterText = styled.p`
	color: white;
	text-align: center;
	text-shadow: 0px 4px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000;
	-webkit-text-stroke-width: 1.5;
	-webkit-text-stroke-color: #000;
	font-family: "Baloo";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin: 0;

	@media (orientation: portrait) {
		@media (max-width: 410px) {
			font-size: 22px;
			line-height: 22px;
		}
		@media (max-width: 379px) {
			font-size: 20px;
			line-height: 20px;
		}
	}
	@media (orientation: landscape) {
		@media (max-height: 390px) {
			font-size: 22px;
			line-height: 22px;
		}
	}
`;

export const Button = styled(ThemeButton)`
	@media (max-width: 414px) {
		font-size: 13px;
		height: 100%;
	}
	@media (max-width: 390px) {
		font-size: 12px;
		height: 100%;
	}
	@media (max-width: 360px) {
		line-height: 22px;
	}
`;

export const BuyItAllButton = styled(ThemeButton)`
	width: 310px;
	height: 57px;
	font-size: 24px;

	@media (orientation: landscape) {
		@media (max-height: 390px) {
			height: 45px;
		}
	}
`;

// prettier-ignore
export const BestValueBadge = styled.div<{ backgroundImage: string }>(({ backgroundImage }) => `
	position: absolute;
	z-index: 1;
	top: -30px;
	right: 0px;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(${backgroundImage});
	background-size: cover;
	transform: rotate(-21deg);
	color: #fff;
	font-family: "Baloo";
	font-size: 13px;
	line-height: 12px;
	text-shadow: 0px 0px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747, 0px 1px 0 #474747, 0px -1px 0 #474747, -1px 0px 0 #474747,
		1px 0px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747, 0px 1px 0 #474747, 0px -1px 0 #474747, -1px 0px 0 #474747,
		1px 0px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
		-1px -1px 0 #474747;
	transition: ease .3s;

	> span {
		width: 40px;
	}

	&.buy-all-button {
		top: 50%;
		transform: translate(50%, -50%) rotate(-21deg);
	}

	&.blocked-offer {
		filter: grayscale(1.0) brightness(45%);
	}

	@media (orientation: landscape) {
		@media (max-width: 932px) and (max-height: 430px) {
			width: 50px;
			height: 50px;
			font-size: 11px;
		}
	}
		
	@media (max-width: 430px) {
		width: 50px;
		height: 50px;
		font-size: 11px;
	}
`);
