import { useEnvContext, useWebStoreContext } from "contexts";
import { FeaturesFlags } from "helpers";
import { DripOfferResponse, Node } from "interfaces/drip-offer";
import { getAvailablePrizes } from "pages/DripOffer/hooks";
import { useEffect, useState } from "react";
import {
	useGetFeatureTrackerQuery,
	useSuppressResponseMutation
} from "redux/apis";
import { onOpenPopUp, selectPopUpManagerState } from "redux/slices";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import useNewDay from "./useNewDay";
import { WsDialogs } from "interfaces";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

export const useDripOffer = (): void => {
	const [flag, setFlag] = useState<{
		dripOffer: boolean;
		hasSeenMainPage: boolean;
	}>({
		dripOffer: false,
		hasSeenMainPage: false
	});

	const { newDay } = useNewDay();
	const dispatch = useAppDispatch();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { variant: dialogVariant } = useDialogModalRedux();
	const { variant } = useAppSelector(selectPopUpManagerState);
	const { userTracker, handleErrorResponse } = useWebStoreContext();

	const [SuppressResponse] = useSuppressResponseMutation();

	const {
		data: dripOfferData,
		refetch: refetchDripOffer,
		isSuccess: isSuccessDripOffer
	}: {
		data?: DripOfferResponse;
		refetch: () => void;
		isSuccess: boolean;
	} = useGetFeatureTrackerQuery(
		{
			FeatureType: "drip-offer",
			isAuthenticated: !!userTracker
		},
		{ skip: !isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER) || !userTracker }
	);

	useEffect(() => {
		if (window.location.pathname === "/special-offers") {
			setFlag((flag) => ({ ...flag, hasSeenMainPage: true }));
		}
	}, [window.location.pathname]);

	useEffect(() => {
		if (newDay) {
			refetchDripOffer();
		}
	}, [newDay]);

	useEffect(() => {
		if (
			isSuccessDripOffer &&
			dripOfferData?.tracker?.isFinished === false &&
			(dripOfferData?.tracker?.nodes?.find(
				(n: Node) => n.state === "available"
			) ||
				!dripOfferData?.tracker?.offerPurchaseDate) &&
			window.location.pathname !== "/" &&
			window.location.pathname !== "/special-offers" &&
			!flag.dripOffer &&
			!flag.hasSeenMainPage
		) {
			setFlag((flag) => ({ ...flag, dripOffer: true }));
			dispatch(onOpenPopUp("drip-offer"));
		}
		const finishedRewardsCollection = () => {
			if (
				dripOfferData?.tracker &&
				getAvailablePrizes(dripOfferData.tracker.nodes) === 0 &&
				dialogVariant !== WsDialogs.XSOLLA_PURCHASE
			) {
				SuppressResponse({})
					.unwrap()
					.then(() => {
						if (!variant) {
							dispatch(onOpenPopUp("drip-offer-missed-event"));
						}
					})
					.catch((error) => {
						handleErrorResponse({ response: error });
					});
			}
		};
		if (
			dripOfferData?.tracker &&
			(dripOfferData.tracker.isFinished ||
				dripOfferData.tracker.isFinished === undefined) &&
			window.location.pathname !== "/"
		) {
			finishedRewardsCollection();
		}
	}, [dripOfferData, isSuccessDripOffer, window.location.pathname]);
};
