import { AbstractHttpService } from "../../../infrastructure/HttpService";
import { AbstractLoginRequest } from "../LoginRequest";
import HttpRequestBuilder from "../../../infrastructure/HttpService/HttpRequest/httpRequestBuilder";
import { ContentType } from "../../../infrastructure/HttpService/headerConstants";
import { RequestAuthOption } from "../../../infrastructure/HttpService/types";
import { ILoginCallbacks } from "./types";
import { getDebugLogger, IDebugLogger } from "../../../utils/debugLogger";
import HttpResponse from "../../../infrastructure/HttpService/HttpResponse";
import { ILoginResponse } from "../../../contexts/WebStoreProvider/types";
import { LoginGoogleAnalyticsHelper } from "../LoginGoogleAnalyticsHelper";

const LOGIN_ENDPOINT = "/rest/user/login";

export default class LoginService {
	private httpService: AbstractHttpService;
	private loginGoogleAnalytics: LoginGoogleAnalyticsHelper;
	// Dont love this... but trying to balance wanting this system to be agnostic of the React App, and
	// not wanting to have to refactor WebStoreContext
	private loginCallbacks: ILoginCallbacks;

	private debug: IDebugLogger = getDebugLogger({
		isEnabled: !false,
		color: "Cyan"
	});

	constructor(
		httpService: AbstractHttpService,
		loginGoogleAnalytics: LoginGoogleAnalyticsHelper,
		loginCallbacks: ILoginCallbacks
	) {
		this.httpService = httpService;
		this.loginCallbacks = loginCallbacks;
		this.loginGoogleAnalytics = loginGoogleAnalytics;
	}

	async login(loginRequest: AbstractLoginRequest): Promise<ILoginResponse> {
		this.loginGoogleAnalytics.reportLoginStart(loginRequest.loginType);
		const syntheticId = localStorage.getItem(
			"store.solitairetripeaks.com:syntheticId"
		);

		const request = new HttpRequestBuilder(LOGIN_ENDPOINT, "POST")
			.withHeaders({
				"Content-Type": ContentType.PlainText,
				"X-GSN-WEBSTORE-SYNTHETIC-ID": JSON.parse(
					syntheticId ? syntheticId : ""
				)
			})
			.withParams({
				loginType: loginRequest.loginType,
				loginCredentials: loginRequest.getCredentialsAsString(),
			})
			.build();

		return this.httpService
			.makeRequest(request, RequestAuthOption.NoAuth)
			.then((response: HttpResponse) => {
				const { success, errorCode, errorMessage } = response.data;

				if (success) {
					this.loginGoogleAnalytics.reportLoginSuccess(loginRequest.loginType);
					this.loginCallbacks.onLoginSuccess(response.data);
				} else {
					this.debug.log({
						iconText: "LoginService",
						message: [
							"Login Call Returned Fail: ",
							`errorCode: ${errorCode}`,
							`errorMessage: ${errorMessage}`
						]
					});
					this.loginGoogleAnalytics.reportLoginFailure(loginRequest.loginType);
					this.loginCallbacks.onLoginFail(response.data);
				}

				return response.data;
			})
			.catch((err) => {
				this.debug.log({
					iconText: "LoginService",
					message: ["Error Calling Login Service: ", err]
				});
				this.loginCallbacks.onLoginError(err);
			});
	}
}
