import { useAppDispatch, useAppSelector } from "hooks";
import { useContext, useEffect } from "react";
import { FeaturesFlags } from "helpers";
import {
	getLimitedDeals,
	onSetStickyDIPData,
	selectStickyDIPState
} from "redux/slices";
import { useEnvContext, useWebStoreContext } from "contexts";
import { useGetOffersQuery, featureTrackerAPI } from "redux/apis";
import { ShopContext } from "pages/StoreFront";
import { PageContent } from "components/PageContent";
import { IItem } from "../SingleItems";
import EnlessOffer from "../EndlessOffer";
import { WaterFallSale } from "../WaterFallSale";
import StickyDIP from "components/StickyDIP";

export const LimitedDealsSection = () => {
	const dispatch = useAppDispatch();
	const { data } = useAppSelector(selectStickyDIPState);
	const { isFeatureFlagEnabled } = useEnvContext();
	const { sectionMenuData } = useContext(ShopContext);
	const { authToken, userTracker } = useWebStoreContext();
	const { data: purchaseOffers } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: !!authToken?.value
	});
	const isWaterfallSaleActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.WATERFALL_SALES)
		);
	};

	const isEndlessOfferActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.ENDLESS_OFFER)
		);
	};

	const isStickyDipActive = () => {
		return isFeatureFlagEnabled(FeaturesFlags.STICKY_DIPS);
	};

	const { data: EndlessOfferData } =
		featureTrackerAPI.endpoints.getFeatureTracker.useQueryState({
			FeatureType: "endless-offer",
			isAuthenticated: !!authToken?.value
		});

	const { data: waterfallData } =
		featureTrackerAPI.endpoints.getFeatureTracker.useQueryState({
			FeatureType: "waterfall-sale",
			isAuthenticated: !!authToken?.value
		});

	const stickyData = data;

	const section = sectionMenuData.find((item: IItem) => {
		if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
			return item.name === "waterfall_sale";
		}
		if (isEndlessOfferActive() && EndlessOfferData?.tracker) {
			return item.name === "endless_offer";
		}
		if (isStickyDipActive() && stickyData) {
			return item.name === "limited_deals";
		}
		return false;
	});

	useEffect(() => {
		if (!userTracker) {
			dispatch(onSetStickyDIPData(undefined));
		} else {
			dispatch(getLimitedDeals());
		}
	}, [userTracker]);

	if (!section || !purchaseOffers) {
		return <></>;
	}

	return (
		<section ref={section?.sectionRef}>
			{section?.name === "waterfall_sale" && isWaterfallSaleActive() && (
				<WaterFallSale />
			)}

			{section?.name === "endless_offer" && isEndlessOfferActive() && (
				<PageContent source={"store_page"} sxContainer={{ paddingBottom: 0 }}>
					<EnlessOffer />
				</PageContent>
			)}

			{section?.name === "limited_deals" && isStickyDipActive() && (
				<PageContent source={"store_page"} sxContainer={{ paddingBottom: 0 }}>
					<StickyDIP />
				</PageContent>
			)}
		</section>
	);
};
