import { Typography } from "@mui/material";
import styled from "styled-components";

export const OffersStyled = styled.div`
	display: flex;
	justify-content: center;
	padding: 7px;

	@media (orientation: portrait) {
		width: 100%;
		height: 640px;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	@media (orientation: landscape) {
		height: 255px;
		align-items: center;
		flex-wrap: nowrap;
	}
`;

export const OfferItem = styled.div`
	//display: grid;
	grid-template-rows: auto;
	background-color: rgba(51, 51, 51, 0.3);
	border: 1px solid #9aeffc;
	border-radius: 12px;
	padding: 0.5rem;
	gap: 0.5rem;
	margin: 3px;
	width: 200px;
	height: auto;
	z-index: 999;
	align-content: stretch;

	@media (orientation: portrait) {
		width: 90%;
		&.one-item {
			min-width: unset;
			img {
				width: 55px;
				height: 55px;
			}
		}
		&.two-items {
			min-width: 250px;
		}
		&.several-items {
			min-width: 200px;
		}
	}
	@media (orientation: landscape) {
		gap: 1.2rem;
		img {
			width: 65px;
			height: 65px;
		}
		&.one-item {
			min-width: 400px;
			width: auto;
		}
		&.two-items {
			min-width: 350px;
		}
		&.several-items {
			min-width: 300px;
		}
	}
`;

export const Items = styled.div`
	justify-content: center;
	align-items: end;
	display: flex;
	flex-wrap: wrap;
`;
/* eslint-disable */
export const RewardItem = styled.div<any>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${(props) => props.width};
	box-sizing: border-box;
	padding: 1px;
	//gap: 0.4rem;
`;
/* eslint-enable */

export const RewardAmount = styled(Typography)`
	color: #fff;
	font-weight: 700;
	font-size: 15px;
	/* @media (orientation: portrait) {
		font-size: 15px;
	} */
`;

export const ItemImage = styled.img`
	width: 46px;
	height: 46px;
	object-fit: contain;
	justify-self: center;
	align-self: center;
`;

export const Footer = styled.div`
	display: grid;
	align-items: center;
	button {
		padding: 0.5rem 0.2rem;
		gap: 0.4rem;
		//font-size: 0.9rem;
	}
	&.loyalty-reward {
		grid-template-columns: minmax(85px, auto) auto;
		grid-gap: 10px;
		button {
			//font-size: 0.8rem;
		}
		@media (orientation: portrait) {
			&.one-item {
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
				width: 100%;
				button {
					width: 100%;
				}
			}
		}
	}
`;

export const LoyaltyPoints = styled.div`
	background-color: rgba(0, 0, 0, 0.51);
	color: #fff;
	font-size: 0.9rem;
	padding: 0.5rem 0.8rem;
	margin: 0.3rem 0rem;
	border-radius: 100px;
	
	> div {
		align-items: center;
		justify-content: center;
		display: flex;
		gap: 0.2rem;
	}
`;
