import { PREFIX } from "./helper";
import { RootState } from "redux/store";
import { getLimitedDeals } from "./thunks";
import { initialState } from "./initial-state";
import { StickyDIPResponse } from "interfaces/sticky-dip";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const stickyDIPSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onSetStickyDIPData: (
			state,
			action: PayloadAction<StickyDIPResponse | undefined>
		) => {
			state.data = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getLimitedDeals.fulfilled, (state, action) => {
			const data = action.payload;
			if (!data || !("offers" in data)) {
				state.data = undefined;
				state.isBogo = false;
				state.isBuyItAll = false;
				return;
			}
			state.isBogo = typeof data.offers[0].rewardMultiplier !== "undefined";
			state.isBuyItAll = !!data.offers.slice(-1)[0].isBuyItAll;
			state.data = data;
		});
	}
});

export const { onSetStickyDIPData } = stickyDIPSlice.actions;
export const selectStickyDIPState = (state: RootState) => state.stickyDip;
export default stickyDIPSlice.reducer;
export * from "./types";
export * from "./thunks";
