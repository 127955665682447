/* eslint @typescript-eslint/no-empty-interface: "off" */
import axios from "axios";
import { DateTime } from "luxon";
import { createContext, useContext, useState } from "react";
import { useEnvContext } from "../../contexts/EnvProvider";
import { WebStoreContext } from "../../contexts/WebStoreProvider";
import {
	IAbstractResponse,
	IAuthResponse,
	IUserTracker
} from "../../contexts/WebStoreProvider/types";
import { getDebugLogger } from "../../utils/debugLogger";
import { IAuthToken } from "../../contexts/WebStoreProvider/types";
import { Moment } from "moment";
import { isMobile } from "react-device-detect";
import { useGoogleAnalyticsContext } from "../../contexts/GoogleAnalyticsProvider";

export const LOCAL_STORAGE_DATE_JUMPED =
	"store.solitairetripeaks.com:dateJumped";

const debug = getDebugLogger({
	isEnabled: true
});
/* eslint-disable-next-line */
const debugLogError = (name: string, error: any) => {
	debug.log({
		iconText: `${name}: [error]`,
		message: [error],
		iconColor: "red"
	});
};
import { getFreeGift } from "../../pages/ClubFreeGift/Controllers/giftController";
import { useQueryClient } from "@tanstack/react-query";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { offersApi } from "redux/apis";
import { useDispatch } from "react-redux";
import { isDebugEnabled } from "redux/slices";

/* eslint-disable */
export interface IDebugWebStoreContext {
	timeJump(
		nowTime: Moment | null,
		deltaDays: number,
		deltaHours?: number,
		deltaMinutes?: number
	): Promise<void>;
	resetTimeJump(): Promise<void>;
	setLoyaltyPoints(points: number): Promise<void>;
	setServerTime(now: string): Promise<void>;
	authToken: IAuthToken | null;
	setAuthTokenTtl(authTokenTtl: number): Promise<boolean>;
	clearInboxMessages(): Promise<boolean>;
	clearFirstLoginReward(): Promise<boolean>;
	clearUser(): Promise<boolean>;
	handleRefresh(): void;
	resetClubShopFreeGift(): Promise<boolean>;
	simulateMemberClicks(): Promise<boolean>;
}
/* eslint-enable */

export interface IUserDataResponse extends IAbstractResponse {
	userData: { userTracker: IUserTracker };
}

export const DebugWebStoreContext = createContext<IDebugWebStoreContext>({
	timeJump: async (): Promise<void> => {
		// do nothing
	},
	resetTimeJump: async (): Promise<void> => {
		// do nothing
	},
	// eslint-disable-next-line
	setLoyaltyPoints: async (points: number): Promise<void> => {
		// do nothing
	},
	// eslint-disable-next-line
	setServerTime: async (now: string): Promise<void> => {
		// do nothing
	},
	// eslint-disable-next-line
	setAuthTokenTtl: async (authTokenTtl: number): Promise<boolean> => {
		return false;
	},
	clearInboxMessages: async (): Promise<boolean> => {
		return false;
	},
	clearFirstLoginReward: async (): Promise<boolean> => {
		return false;
	},
	clearUser: async (): Promise<boolean> => {
		return false;
	},
	handleRefresh: async () => {
		// no op
	},
	resetClubShopFreeGift: async (): Promise<boolean> => {
		return false;
	},
	simulateMemberClicks: async (): Promise<boolean> => {
		return false;
	},
	authToken: null
});
/* eslint-disable-next-line */
function DebugWebStoreProvider({ children }: any) {
	const {
		authToken,
		handleErrorResponse,
		userTracker,
		createHeaders,
		fbAccessToken,
		setAuthToken,
		forceReloadStoreRaw,
		syncUserTracker,
		logout,
		setNowTime
	} = useContext(WebStoreContext);
	const { showErrorDialogHelper } = useDialogModalRedux();
	const { syntheticId } = useGoogleAnalyticsContext();
	const { backendUri } = useEnvContext();
	const queryClient = useQueryClient();
	/* eslint-disable-next-line */
	const [debugUserData, setDebugUserData] = useState<IUserTracker | null>(
		userTracker
	);
	const dispatch = useDispatch();

	const handleRefresh = async () => {
		dispatch(isDebugEnabled());
		await forceReloadStoreRaw();
		await syncUserTracker();
	};

	//TODO:bfloyd
	const getDebugUserData = async (): Promise<void> => {
		if (!authToken?.value) {
			// showErrorDialog("no_auth_token_found", "No auth token found");
			console.warn("no_auth_token_found", "No auth token found");
			logout();
			return;
		}

		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			})
		};
		// TODO: figure out why this TS isn't happy
		// axios.get<IUserTrackerResponse>(uri, extraConfig)
		const uri = "/rest/debug/user/data";
		await axios
			.get<IUserDataResponse>(uri, requestConfig)
			.then(async (response) => {
				if (response.data && response.data.success) {
					setDebugUserData(response.data.userData.userTracker);
				} else {
					handleErrorResponse(response.data);
				}

				return response.data;
			})
			.catch((err) => {
				debugLogError("getDebugUserData", err);
				showErrorDialogHelper(err);
			});
	};

	const invalidateOffersTag = () => {
		dispatch(offersApi.util.invalidateTags(["offersTag"]));
	};
	const timeJump = async (
		nowTime: Moment | null,
		_deltaDays: number,
		_deltaHours?: number,
		_deltaMinutes?: number
	): Promise<void> => {
		const queryParams: {
			dateTime?: string;
			deltaDays?: number;
		} = {};
		if (nowTime) {
			const userDateTime = DateTime.fromISO(nowTime.toISOString());
			queryParams.dateTime = userDateTime
				.plus({
					days: _deltaDays ?? 0,
					hours: _deltaHours ?? 0,
					minutes: _deltaMinutes ?? 0
				})
				.toISO();
			localStorage.setItem(
				LOCAL_STORAGE_DATE_JUMPED,
				userDateTime
					.plus({
						days: _deltaDays ?? 0,
						hours: _deltaHours ?? 0,
						minutes: _deltaMinutes ?? 0
					})
					.toISO()
			);
		} else {
			queryParams.deltaDays = _deltaDays;
		}
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			}),
			params: queryParams
		};
		const uri = "/rest/debug/user/timeJump";
		await axios
			.post<IAbstractResponse>(uri, {}, requestConfig)
			.then((response) => {
				if (response.data && response.data.success) {
					if (setNowTime && response.headers["x-gsn-webstore-now"]) {
						setNowTime(response.headers["x-gsn-webstore-now"]);
					}
					handleRefresh();
					invalidateOffersTag();
					return response.data;
				} else {
					handleErrorResponse(response.data);
				}
			})
			.catch((err) => {
				debugLogError("timeJump", err);
				showErrorDialogHelper(err);
			});

		await getDebugUserData();
	};

	const resetTimeJump = async (): Promise<void> => {
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			})
		};
		const uri = "/rest/debug/user/resetTimeJump";
		await axios
			.post<IAbstractResponse>(uri, {}, requestConfig)
			.then((response) => {
				if (response.data && response.data.success) {
					if (setNowTime && response.headers["x-gsn-webstore-now"]) {
						setNowTime(response.headers["x-gsn-webstore-now"]);
					}
					handleRefresh();
					invalidateOffersTag();
					return response.data;
				} else {
					handleErrorResponse(response.data);
				}
			})
			.catch((err) => {
				debugLogError("resetTimeJump", err);
				showErrorDialogHelper(err);
			});

		await getDebugUserData();
	};

	const setAuthTokenTtl = async (authTokenTtl: number): Promise<boolean> => {
		if (!fbAccessToken) {
			/* eslint-disable */
			showErrorDialogHelper({
				name: "no_facebook_token",
				message: "Please logout and login again with Facebook"
			} as any);
			/* eslint-enable */

			return false;
		}

		const queryParams: {
			facebookAccessToken: string;
			ttlSeconds: number;
		} = {
			facebookAccessToken: fbAccessToken,
			ttlSeconds: authTokenTtl
		};
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			}),
			params: queryParams
		};
		const uri = "/rest/user/authToken";
		return await axios
			.post<IAuthResponse>(uri, {}, requestConfig)
			.then((response) => {
				if (response.data && response.data.success) {
					const { value, expiresAt } = response.data;
					setAuthToken({ value, expiresAt });
					return true;
				} else {
					handleErrorResponse(response.data);
					return false;
				}
			})
			.catch((err) => {
				debugLogError("setAuthTokenTtl", err);
				showErrorDialogHelper(err);
				return false;
			});
	};

	const clearInboxMessages = async (): Promise<boolean> => {
		return await clearServerData("/rest/debug/user/clearInboxMessages");
	};

	const clearFirstLoginReward = async (): Promise<boolean> => {
		return await clearServerData("/rest/debug/user/clearFirstLoginReward");
	};

	const clearUser = async (): Promise<boolean> => {
		return await clearServerData("/rest/debug/user/clear");
	};

	const clearServerData = async (uri: string): Promise<boolean> => {
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			})
		};
		return await axios
			.post<IAuthResponse>(uri, {}, requestConfig)
			.then((response) => {
				if (!response.data || !response.data.success) {
					handleErrorResponse(response.data);
				}
				syncUserTracker();
				return true;
			})
			.catch((err) => {
				debugLogError("clearServerData", err);
				showErrorDialogHelper(err);
				return false;
			});
	};

	const setLoyaltyPoints = async (points: number): Promise<void> => {
		const queryParams: {
			value: number;
		} = {
			value: points
		};
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			}),
			params: queryParams
		};
		const uri = "/rest/debug/user/setLoyaltyPoints";
		await axios
			.post<IAbstractResponse>(uri, {}, requestConfig)
			.then((response) => {
				if (response.data && response.data.success) {
					handleRefresh();
					return response.data;
				} else {
					handleErrorResponse(response.data);
				}
			})
			.catch((err) => {
				debugLogError("setLoyaltyPoints", err);
				showErrorDialogHelper(err);
			});

		await getDebugUserData();
	};

	const setServerTime = async (now: string): Promise<void> => {
		const queryParams: {
			dateTime: string;
		} = {
			dateTime: now
		};
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			}),
			params: queryParams
		};
		const uri = "/rest/debug/user/timeJump";
		await axios
			.post<IAbstractResponse>(uri, {}, requestConfig)
			.then((response) => {
				if (response.data && response.data.success) {
					if (setNowTime && response.headers["x-gsn-webstore-now"]) {
						setNowTime(response.headers["x-gsn-webstore-now"]);
					}
					handleRefresh();
					invalidateOffersTag();
					location.reload();
					return response.data;
				} else {
					handleErrorResponse(response.data);
				}
			})
			.catch((err) => {
				debugLogError("setServerTime", err);
				showErrorDialogHelper(err);
			});

		await getDebugUserData();
	};

	const resetClubShopFreeGift = async (): Promise<boolean> => {
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			})
		};
		const uri = "/rest/debug/club/freeGift/reset";
		return await axios
			.post<IAbstractResponse>(uri, {}, requestConfig)
			.then(async (response) => {
				if (response.data && response.data.success) {
					const data = await getFreeGift();
					queryClient.setQueryData(["free_gift"], data);
				} else {
					handleErrorResponse(response.data);
				}
				return true;
			})
			.catch((err) => {
				debugLogError("freeGiftReset", err);
				showErrorDialogHelper(err);
				return false;
			});
	};
	const simulateMemberClicks = async (): Promise<boolean> => {
		const requestConfig = {
			baseURL: backendUri,
			headers: createHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: `Bearer ${authToken?.value}`,
				"X-GSN-WEBSTORE-DEVICE-TYPE": isMobile ? "mobile" : "desktop",
				"X-GSN-WEBSTORE-SYNTHETIC-ID": syntheticId
			})
		};
		const uri = "/rest/debug/club/freeGift/simulateMemberClicks";
		return await axios
			.post<IAbstractResponse>(uri, {}, requestConfig)
			.then(async (response) => {
				if (response.data && response.data.success) {
					const data = await getFreeGift();
					queryClient.setQueryData(["free_gift"], data);
				} else {
					handleErrorResponse(response.data);
				}
				return true;
			})
			.catch((err) => {
				debugLogError("simulateMemberClicks", err);
				showErrorDialogHelper(err);
				return false;
			});
	};

	return (
		<DebugWebStoreContext.Provider
			value={{
				timeJump,
				resetTimeJump,
				setLoyaltyPoints,
				setServerTime,
				setAuthTokenTtl,
				authToken,
				clearInboxMessages,
				clearFirstLoginReward,
				clearUser,
				handleRefresh,
				resetClubShopFreeGift,
				simulateMemberClicks
			}}
		>
			{children}
		</DebugWebStoreContext.Provider>
	);
}

export default DebugWebStoreProvider;
