import { Grid } from "@mui/material";
import tiki from "assets/drip-offer/tiki.gif";
import { IShopRewardItem } from "contexts/WebStoreProvider/types";
import useBreakpoints from "hooks/useBreakpoints";
import { PurchaseOfferSingleItem } from "interfaces";
import { LoyaltyPoints, Tiki } from "pages/DripOffer/styles";
import { CrownIcon } from "utils/svgIcons";

interface OfferProps {
	firstOffer: {
		offer: PurchaseOfferSingleItem;
		items: IShopRewardItem[];
	} | undefined;
	isPopup?: boolean;
}

export const Offer = ({ firstOffer, isPopup }: OfferProps) => {

	const { isSmDown } = useBreakpoints();

	if (!isPopup) {
		return (
			<>
				<Grid item xs={12} md={11} zIndex={1} mx="auto">
					{!isSmDown && (
						<Grid item sm={10} md={8.4} lg={7} mx="auto">
							<h3>Get this now:</h3>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12} sm={10} md={7.7} lg={6.5} zIndex={1} mx="auto" mb={1} position="relative">
					<Tiki src={tiki} alt="Tiki saying hi" />
					<Grid container className="ticket">
						{isSmDown && (
							<Grid item xs={12}>
								<h3 className="mobile">Get this now:</h3>
							</Grid>
						)}
						<Grid item xs={5} lg={5} position="relative" display="flex" justifyContent="center" alignItems="center">
							<div className="item-background" />
							<div className="item-content large">
								<img src={firstOffer?.items[0]?.imageUrl} alt="" />
								<p className={`large ${firstOffer?.items?.[0]?.id === "coin" && "is-coin"}`}>
									{firstOffer?.items?.[0]?.id !== "coin" && "x"}{firstOffer?.items[0]?.value?.toLocaleString('en-US')}
								</p>
							</div>
						</Grid>
						<Grid item xs={2} lg={2} className="plus" textAlign="center">
							+
						</Grid>
						<Grid item xs={5} lg={5} position="relative" display="flex" justifyContent="center" alignItems="center">
							<div className="item-background" />
							<div className="item-content large">
								<img src={firstOffer?.items[1]?.imageUrl} alt="" />
								<p className={`large ${firstOffer?.items?.[1]?.id === "coin" && "is-coin"}`}>
									{firstOffer?.items?.[1]?.id !== "coin" && "x"}{firstOffer?.items[1]?.value?.toLocaleString('en-US')}
								</p>
							</div>
						</Grid>
						<Grid item mt={1} mx="auto">
							<LoyaltyPoints>
								Earn <CrownIcon /> {firstOffer?.offer.loyaltyPointReward?.toLocaleString('en-US')}
							</LoyaltyPoints>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} zIndex={1} mx="auto">
					<Grid item xs={12} sm={10} md={7.7} lg={6.5} mx="auto">
						<h3>Claim a new prize each day you login:</h3>
					</Grid>
				</Grid>
			</>
		);
	} else {
		return (
			<>
				<Grid item xs={12} sm={12} mx="auto">
					{!isSmDown && (
						<Grid item sm={12} mx="auto">
							<h3>Get this now:</h3>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12} sm={12} mx="auto" position="relative">
					<Tiki src={tiki} alt="Tiki saying hi" isPopup={true} />
					<Grid container className="ticket" alignItems="center">
						{isSmDown && (
							<Grid item xs={12}>
								<h3 className="mobile">Get this now:</h3>
							</Grid>
						)}
						<Grid item xs={5} sm={5.2} position="relative" display="flex" justifyContent="center" alignItems="center">
							<div className="item-background" />
							<div className="item-content large">
								<img src={firstOffer?.items[0]?.imageUrl} alt="" />
								<p className={`large ${firstOffer?.items?.[0]?.id === "coin" && "is-coin"}`}>
									{firstOffer?.items?.[0]?.id !== "coin" && "x"}{firstOffer?.items[0]?.value?.toLocaleString('en-US')}
								</p>
							</div>
						</Grid>
						<Grid item xs={2} sm={1.6} className="plus" textAlign="center">
							+
						</Grid>
						<Grid item xs={5} sm={5.2} position="relative" display="flex" justifyContent="center" alignItems="center">
							<div className="item-background" />
							<div className="item-content large">
								<img src={firstOffer?.items[1]?.imageUrl} alt="" />
								<p className={`large ${firstOffer?.items?.[1]?.id === "coin" && "is-coin"}`}>
									{firstOffer?.items?.[1]?.id !== "coin" && "x"}{firstOffer?.items[1]?.value?.toLocaleString('en-US')}
								</p>
							</div>
						</Grid>
						<Grid item mt={.5} mx="auto">
							<LoyaltyPoints>
								Earn <CrownIcon /> {firstOffer?.offer.loyaltyPointReward?.toLocaleString('en-US')}
							</LoyaltyPoints>
						</Grid>
					</Grid>
				</Grid>
			</>
		)
	}
};
