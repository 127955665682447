import { useEffect } from "react";
import classNames from "classnames";
import { PopUp, Wrapper } from "./styles";
import { variantLoader } from "./variant-loader";
import useBreakpoints from "hooks/useBreakpoints";
import { LeftRightAnimation } from "theme/animations";
import { useAppDispatch, useAppSelector } from "hooks";
import { useInitialActions, useReportEventOnClose } from "./hooks";
import {
	onClosePopUp,
	onDIPtrigger,
	selectPopUpManagerState
} from "redux/slices";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";
import { links } from "helpers";
import { useLocation } from "react-router-dom";

interface PopUpManagerProps { }

const PopUpManager: React.FC<PopUpManagerProps> = () => {
	const dispatch = useAppDispatch();
	const { isSmDown } = useBreakpoints();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { variant } = useAppSelector(selectPopUpManagerState);
	const location = useLocation();
	useInitialActions();
	const report = useReportEventOnClose(variant);

	const getWindowSize = () => {
		const { innerHeight } = window;
		const vh = innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	};

	useEffect(() => {
		getWindowSize();
	}, [window.innerHeight]);

	const isScalable = () => {
		if (
			variant === "drip-offer"
			|| variant === "drip-offer-reward"
			|| variant === "drip-offer-info"
			|| variant === "endless-offer-reward-collected"
			|| variant === "daily-login-streak-loyalty-points"
			|| variant === "drip-offer-end"
		) {
			return true;
		}

		return false;
	};

	return variant ? (
		<PopUp className={isScalable() ? "scale" : ""}>
			<LeftRightAnimation>
				<Wrapper className={classNames(isSmDown && "mobile")}>
					{variantLoader(variant)}
					{!isScalable() && (
						<CloseIconButton
							onClose={() => {
								// Workaround: Popup does not support adding functionality in each variant on close
								if (location.pathname === links.free_daily_gifts.path) {
									dispatch(onDIPtrigger("collectDailyGift"));
								}
								//
								dispatch(onClosePopUp());
								if (typeof report !== "undefined") {
									reportEvent(report["name"], {
										...report["opts"]
									});
								}
							}}
						/>
					)}
				</Wrapper>
			</LeftRightAnimation>
		</PopUp>
	) : null;
};

export default PopUpManager;
