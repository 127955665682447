import { WsDialogs } from "interfaces";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FeaturesFlags, links } from "helpers";
import { useLocation } from "react-router-dom";
import { useEnvContext } from "contexts/EnvProvider";
import { useAppDispatch, useAppSelector } from "hooks";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { getDIPs, onDIPtrigger, selectDipState, selectPopUpManagerState } from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";

export const useInitialActions = (): void => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { userTracker } = useWebStoreContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const [sessionStartDipOpened, setSessionStartDipOpened] =
		useState<boolean>(false);
	const isDIPsEnabled = isFeatureFlagEnabled(FeaturesFlags.DIPS);
	const { data, queueTriggers, opened, triggerCached, pendingData } =
		useAppSelector(selectDipState);
	const isMegaMenuOpen = useSelector(
		(state: RootState) => state.megaMenu.isOpen
	);
	const { variant } = useDialogModalRedux();
	const { variant: popupManagerVariant } = useAppSelector(selectPopUpManagerState);

	useEffect(() => {
		if (!isDIPsEnabled || !userTracker) {
			return;
		}
		dispatch(getDIPs());
	}, [userTracker, isDIPsEnabled]);

	// 🔦 dispatch sessionStart trigger when DIP data is on state
	useEffect(() => {
		if (
			data.success &&
			!isMegaMenuOpen &&
			!pendingData &&
			variant !== WsDialogs.LOGIN_REWARD_CONFIRMATION &&
			popupManagerVariant !== "drip-offer" &&
			!sessionStartDipOpened
		) {
			dispatch(onDIPtrigger("sessionStart"));
			setSessionStartDipOpened(true);
		}
	}, [data.success, isMegaMenuOpen]);

	useEffect(() => {
		if (location.pathname !== links.home.path) {
			dispatch(onDIPtrigger("cache-mode"));
		}
	}, [location.pathname]);

	// When a DIP is closed we check if there is a DIP on queue to show it.
	// Queue DIPs are created when a DIP try to be displayed in the same
	// time that there's another one on the screen
	useEffect(() => {
		if (!opened && !!queueTriggers.length) {
			dispatch(onDIPtrigger(queueTriggers[0]));
		}
	}, [opened, queueTriggers]);

	// When a DIP is closed we check if there is a DIP on cache.
	useEffect(() => {
		if (!opened && triggerCached) {
			dispatch(onDIPtrigger("cache-mode"));
		}
	}, [opened, triggerCached]);
};
