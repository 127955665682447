import { IShopPurchaseOfferMultiItem } from "contexts/WebStoreProvider/types";
/* eslint-disable */
export const sortRewardsWithSpecificIdAtEnd = (
	arr: any[],
	specificId: string
) => {
	arr.sort((a, b) => (a.id === specificId ? 1 : b.id === specificId ? -1 : 0));
	return arr;
};
/* eslint-enable */

export const sortOfferByPriority = (arr: IShopPurchaseOfferMultiItem[]) => {
	arr.sort((a, b) => a.priority - b.priority);
	return arr;
};
