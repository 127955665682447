import { useCallback, useState } from "react";
import { isMobile } from "react-device-detect";
import { selectDialogState } from "redux/slices";
import Button from "components/Buttons/ThemeButton";
import CloseIconButton from "../lib/CloseIconButton";
import { useAppDispatch, useAppSelector } from "hooks";
import { onSuppress } from "redux/slices/web-store/thunks";
import BackToGameButton from "components/Buttons/BackToGameButton";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	Grid,
	Typography,
	useMediaQuery
} from "@mui/material";

const buttonStyles = {
	paddingTop: ".75rem",
	paddingBottom: ".75rem",
	marginBottom: 1,
	width: "100%"
};

const DialogShopPurchaseSuccess = ({
	show,
	onCloseClick
}: {
	show: boolean;
	// eslint-disable-next-line
	onCloseClick: (event?: any, reason?: string) => void;
}) => {
	const dispatch = useAppDispatch();
	const [suppress, setSuppress] = useState<boolean>(false);
	const { props } = useAppSelector(selectDialogState);
	const { reportEvent } = useGoogleAnalyticsContext();
	const isLandscapeMobile = useMediaQuery("(max-width: 932px) and (max-height: 440px)");
	const [
		isSuppressOrderConfirmationChecked,
		setIsSuppressOrderConfirmationChecked
	] = useState(false);

	const { postUserMetadata } = props || {
		// eslint-disable-next-line
		postUserMetadata: (arr: any) => undefined
	};
	const labelText = "Don’t show me this again";
	const label = {
		inputProps: {
			"aria-label": labelText,
			onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
				setIsSuppressOrderConfirmationChecked(event.target.checked);
				const userMetaData = {
					key: "suppressOrderConfirmation",
					value: event.target.checked ? "true" : "false"
				};
				postUserMetadata && postUserMetadata(userMetaData);
			}
		}
	};

	const onSuppressOrder = useCallback(() => {
		if (suppress) {
			dispatch(onSuppress({ key: "suppressOrderConfirmation", value: "true" }));
			localStorage.setItem(
				"store.solitairetripeaks.com:suppressConfirmation",
				"true"
			);
		}
	}, [suppress]);

	return (
		<Dialog
			open={show}
			onClose={onCloseClick}
			sx={{
				zIndex: 99999,
				".MuiPaper-root": {
					borderRadius: 6,
					paddingBottom: ".15rem",
				}
			}}
		>
			<DialogTitle>
				<CloseIconButton
					onClose={() => {
						onCloseClick?.();
						reportEvent("dialog_x_close", {
							source: "loyalty_purchase_success_dialog"
						});
					}}
				/>
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: 0 }}>
				<DialogContentText sx={{ padding: !isLandscapeMobile ? ".75rem 1rem" : ".14rem 1rem" }}>
					<Typography
						variant="h6"
						component="strong"
						sx={{
							fontWeight: "bold",
							margin: "1rem 0 .75rem",
							display: "block",
							textAlign: "center",
							fontSize: { xs: "160%", sm: !isLandscapeMobile ? "200%" : "100%" },
							lineHeight: 1,
							color: "#333333"
						}}
					>
						Your purchase is now in your in-game inbox.
					</Typography>
					<Typography
						sx={{
							margin: "8px 0",
							fontSize: { sm: !isLandscapeMobile ? "100%" : "80%" }
						}}
					>
						Thank you for using our Tiki Solitaire TriPeaks Web Shop. Your
						purchase is now waiting to be collected in your in-game inbox.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
				<Grid
					container
					sx={{
						padding: "0 2rem",
						flexDirection: "row-reverse",
						justifyContent: "center"
					}}
				>
					<Grid item xs={12} sm={7}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={isMobile ? 6 : 12}>
								<Button
									onClick={() => {
										onSuppressOrder();
										onCloseClick?.();
										reportEvent("continue_shopping_click", {
											source: "shop_purchase_success_dialog"
										});
									}}
									sx={{
										...buttonStyles
									}}
								>
									Okay
								</Button>
							</Grid>
							{isMobile && (
								<Grid item xs={12} sm={6}>
									<BackToGameButton
										source={"shop_purchase_success_dialog"}
										themeColor="secondary"
										sx={{
											...buttonStyles,
											backgroundColor: "#4088B7",
											"&:hover": {
												backgroundColor: "#25506d"
											}
										}}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12} sm={5}>
						<Typography sx={{ margin: "8px 0", fontSize: "12px" }}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											onClick={() => {
												setSuppress(!suppress);
											}}
											checked={isSuppressOrderConfirmationChecked}
											color="success"
											{...label}
										/>
									}
									label={labelText}
									disableTypography={true}
								/>
							</FormGroup>
						</Typography>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export { DialogShopPurchaseSuccess };
