/* eslint-disable @typescript-eslint/no-unused-vars */

import redRibbon from "assets/endless-offer/ribbon-red.png";
import goldRibbon from "assets/endless-offer/ribbon-gold.png";
import lockIcon from "assets/endless-offer/padlock.png";
import {
	CardHeader,
	Container,
	DebugInfo,
	ItemImage,
	Items,
	LockImage,
	LoyalBox,
	LoyalBoxWrap,
	RewardAmount,
	RewardItem,
	Ribbon,
	RibbonText,
	StyledButton
} from "./styles";
import { crownWhiteIconPath } from "utils/svgIcons";
import useRewardsById from "hooks/useRewardsById";
import { EndlessNodeProps, EndlessOfferNode } from "interfaces/endless-offer";
import { useWebStoreContext } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { useAppDispatch } from "hooks";
import { featureTrackerAPI, useClaimRewardMutation } from "redux/apis";
import { useCallback } from "react";
import { onOpenPopUp } from "redux/slices";
import { WsDialogs } from "interfaces";
import Footer from "./lib/Footer";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const EndlessNode: React.FC<EndlessNodeProps> = ({
	className,
	node,
	offer,
	isFirstNode,
	isFetching,
	containerHeight,
	containerWidth,
	backgroundUrl,
	ribbonUrl
}) => {
	const displayRewards = useRewardsById(offer?.rewardId || node?.rewardId);
	const {
		wsStoreBuy,
		authToken,
		suppressOrderConfirmation,
		postUserMetadata,
		handleErrorResponse
	} = useWebStoreContext();

	const { openDialog } = useDialogModalRedux();
	const dispatch = useAppDispatch();

	const [
		ClaimReward,
		{ isLoading: IsClaimLoading, isSuccess: IsClaimSuccess }
	] = useClaimRewardMutation();

	const showDebugLabel = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);

	const invalidateFeatureTracker = () => {
		dispatch(
			featureTrackerAPI.util.invalidateTags([
				{
					type: "featureTrackerTag",
					id: "endless-offer"
				}
			])
		);
	};

	const onClickBuy = async (node: EndlessOfferNode) => {
		if (!authToken?.value) {
			return;
		}

		if (!offer) {
			await ClaimReward({
				eventId: node.eventId,
				nodeIndex: node.nodeIndex
			})
				.unwrap()
				.then(() => dispatch(onOpenPopUp("endless-offer-reward-collected")))
				.catch((error) => {
					handleErrorResponse({ response: error });
					invalidateFeatureTracker();
				});
		} else {
			const invoice = await wsStoreBuy({
				offerId: node?.offerId || "",
				endlessOfferEventId: node.eventId,
				endlessOfferNodeIndex: node.nodeIndex
			});
			if (invoice) {
				openDialog(WsDialogs.XSOLLA_PURCHASE, {
					xsollaToken: invoice.xsollaToken,
					xsollaHost: invoice.xsollaHost,
					section: "shop",
					source: "endlessoffer",
					suppressOrderConfirmation,
					postUserMetadata
				});
			} else {
				invalidateFeatureTracker();
			}
		}
	};

	return (
		<Container
			className={className}
			image={backgroundUrl}
			containerHeight={containerHeight}
			containerWidth={containerWidth}
		>
			<CardHeader className={className}>
				{node.nodeBadgeText && (
					<Ribbon image={ribbonUrl} className={className}>
						<RibbonText>{node?.nodeBadgeText}</RibbonText>
					</Ribbon>
				)}
			</CardHeader>
			<Items className={className}>
				{displayRewards?.map((displayReward) => (
					<RewardItem
						key={displayReward.id}
						//width={"50%"}
						filter={isFirstNode ? 0 : 1}
					>
						<ItemImage
							className={className}
							src={displayReward.imageUrl}
							alt=""
						/>
						<RewardAmount className={className}>
							{displayReward.description}
						</RewardAmount>
					</RewardItem>
				))}
			</Items>

			<Footer className={className}>
				<LoyalBoxWrap
					className={className}
					sx={{ fontSize: { xs: "12px", sm: "88%", md: "90%" } }}
				>
					{offer && (
						<LoyalBox className={className}>
							Earn
							<img
								src={crownWhiteIconPath}
								alt="crown-icon"
								style={{ padding: "0", margin: "0 .33rem" }}
							/>
							{offer.loyaltyPointReward}
						</LoyalBox>
					)}
				</LoyalBoxWrap>

				<StyledButton
					variant="contained"
					className={className}
					color="success"
					size="large"
					disabled={!isFirstNode}
					style={{
						pointerEvents: isFetching || IsClaimLoading ? "none" : "auto"
						//isFetching || IsClaimLoading || IsClaimSuccess ? "none" : "auto"
					}}
					onClick={() => onClickBuy(node)}
					sx={{
						zIndex: isFirstNode ? 6 : null
					}}
				>
					{offer ? offer.price + " USD" : "FREE"}
					{!isFirstNode && <LockImage src={lockIcon}></LockImage>}
				</StyledButton>
			</Footer>
			{showDebugLabel && (
				<DebugInfo className={className}>
					{offer?.rewardId || node?.rewardId}
				</DebugInfo>
			)}
		</Container>
	);
};

export default EndlessNode;
