// import { useContext } from "react";
import { useContext, useEffect, useState } from "react";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { IShopPurchaseOfferSingleItem } from "contexts/WebStoreProvider/types";
import { SingleItemsSkeleton } from "../../skeleton";
import { offersApi, useGetOffersQuery } from "redux/apis";
import { ShopContext } from "../../index";
import { useDispatch } from "react-redux";
import { OfferSection } from "..";
import useNewDay from "hooks/useNewDay";
import { useAppSelector } from "hooks";
import { selectShopSectionsState } from "redux/slices/shop-sections";

interface IOffer {
	sectionName: string;
}

export interface IItem {
	name: string;
}

const SingleItems = () => {
	const { authToken } = useWebStoreContext();
	const { shopSections } = useAppSelector(selectShopSectionsState);
	const [content, setContent] = useState<JSX.Element[]>();
	const dispatch = useDispatch();
	const { newDay } = useNewDay();
	const { data: shop, refetch } = useGetOffersQuery(
		{
			offerType: "singleItem",
			isAuthenticated: !!authToken?.value
		},
		{ pollingInterval: 600000 }
	);
	const { sectionMenuData } = useContext(ShopContext);

	useEffect(() => {
		if (!shopSections?.mainShopSections || !shop || !sectionMenuData.length) {
			return;
		}

		const filteredSections = shopSections?.mainShopSections.filter((data) =>
			shop.offers?.find(
				(of: IOffer) =>
					of.sectionName === data.name && of.sectionName !== "limited_deals"
			)
		);

		const content = filteredSections.map((data) => {
			const sectionOffers = shop?.offers.filter(
				(of: IOffer) => of.sectionName === data.name
			);
			const sectionProps = {
				data,
				sectionRef: sectionMenuData.find(
					(item: IItem) => item.name === data.name
				)?.sectionRef,
				uniqueKey: data.name
			};

			return (
				<OfferSection
					{...sectionProps}
					key={data.name}
					sectionOffers={sectionOffers as IShopPurchaseOfferSingleItem[]}
				/>
			);
		});

		setContent(content);
	}, [shop, sectionMenuData]);

	useEffect(() => {
		dispatch(
			offersApi.util.invalidateTags([
				{
					type: "offersTag",
					id: "singleItem"
				}
			])
		);
	}, [sectionMenuData]);

	useEffect(() => {
		if (newDay) {
			refetch();
		}
	}, [newDay]);

	if (!content) {
		return <SingleItemsSkeleton />;
	}

	return <>{content}</>;
};

export default SingleItems;
