import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled, Typography } from "@mui/material";
import Button from "components/Buttons/ThemeButton";
import CloseIconButton from "../lib/CloseIconButton";
import BackToGameButton from "components/Buttons/BackToGameButton";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useEnvContext } from "contexts/EnvProvider";
import { isMobile } from "react-device-detect";

const buttonStyles = {
	paddingTop: ".75rem",
	paddingBottom: ".75rem",
	marginBottom: 1,
	width: "calc(100% - 3rem)",
	marginLeft: 2,
	marginRight: 2
};

const ButtonContainer = styled("div")({
	width: "100%",
	paddingLeft: "16px"
});

const DialogLoyaltyPurchaseSuccess = ({
	show,
	onCloseClick
}: {
	show: boolean;
	onCloseClick: () => void;
}) => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const { cdnPrefix } = useEnvContext();
	const backgroundImage = `url(${cdnPrefix}/spec_dialogs/success.png)`;

	return (
		<Dialog
			open={show}
			onClose={onCloseClick}
			style={{ zIndex: 999999 }}
			sx={{
				".MuiPaper-root": {
					borderRadius: 6,
					paddingBottom: ".15rem",
					width: "462px"
				}
			}}
		>
			<DialogTitle
				sx={{
					height: "199px",
					padding: 0,
					backgroundSize: "cover",
					backgroundColor: "#3B2FC5",
					backgroundImage: backgroundImage,
					backgroundPosition: "center",
					overflow: "hidden"
				}}
			>
				<CloseIconButton
					onClose={() => {
						onCloseClick && onCloseClick();
						reportEvent("dialog_x_close", {
							source: "loyalty_purchase_success_dialog"
						});
					}}
				/>
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: 0 }}>
				<DialogContentText sx={{ padding: ".75rem 1rem" }}>
					<Typography
						variant="h6"
						component="strong"
						sx={{
							fontWeight: "bold",
							margin: "1rem 0 .75rem",
							display: "block",
							textAlign: "center",
							fontSize: "130%",
							lineHeight: 1,
							color: "#333333"
						}}
					>
						Thanks for your purchase!
					</Typography>
					<Typography sx={{ margin: "8px 0" }}>
						Your purchase has been completed. Your items are waiting in your
						in-game inbox to be collected.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
				<ButtonContainer>
					<Button
						onClick={() => {
							onCloseClick && onCloseClick();
							reportEvent("continue_shopping_click", {
								source: "loyalty_purchase_success_dialog"
							});
						}}
						sx={{
							...buttonStyles
						}}
					>
						Continue Shopping
					</Button>
					{isMobile ? (
						<BackToGameButton
							source={"loyalty_purchase_success_dialog"}
							themeColor="secondary"
							sx={{
								...buttonStyles,
								backgroundColor: "#4088B7",
								"&:hover": {
									backgroundColor: "#25506d"
								}
							}}
						/>
					) : null}
				</ButtonContainer>
			</DialogActions>
		</Dialog>
	);
};

export { DialogLoyaltyPurchaseSuccess };
