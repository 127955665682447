import {
	DialogClaimYourFreeLoginGift,
	DialogLoginToContinue,
	DialogYourAccountIsNotConnected,
	DialogDailyGiftConfirmation,
	DialogXsollaPurchase,
	DialogErrorInsufficientLoyaltyPointBalance,
	DialogLoyaltyFaq,
	DialogLoyaltyPurchaseConfirm,
	DialogLoyaltyPurchaseSuccess,
	DialogShopPurchaseSuccess,
	DialogGeneric
} from "components/AppModalDialogsV2/Dialogs/";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { DialogVariant, WsDialogs } from "interfaces";
import { useEffect, useState } from "react";
import { getDebugLogger } from "utils/debugLogger";
import { removeScopelyStateSession } from "contexts/ScopelyIdentityProvider/helpers";
import { DialogLoginRewardConfirmationV2 } from "./Dialogs/DialogLoginRewardConfirmationV2";

const debugColor = "rgb(0, 191, 255)";
const debug = getDebugLogger({
	isEnabled: !false,
	color: debugColor
});

const AppModalDialogs = () => {
	const { reportEvent } = useGoogleAnalyticsContext();
	const {
		openLoginToContinueDialog,
		setCurrentOffer,
		authToken,
		handleRefresh,
		isAutoLoginIn
	} = useWebStoreContext();

	const [myVariant, setMyVariant] = useState<DialogVariant | null>(null);

	const { variant, openDialog, dialogState, closeDialog } =
		useDialogModalRedux();

	const handleClose = () => {
		const { cbClose } = dialogState;
		cbClose && cbClose();
		closeDialog();
	};

	useEffect(() => {
		setMyVariant(variant || null);
	}, [variant]);

	return (
		<>
			<DialogGeneric
				show={myVariant === WsDialogs.DEFAULT || myVariant === WsDialogs.ERROR}
				onCloseClick={handleClose}
			/>
			<DialogClaimYourFreeLoginGift
				show={myVariant === WsDialogs.CLAIM_MY_REWARD && !isAutoLoginIn}
				onCloseClick={() => {
					handleClose();
					reportEvent("dialog_x_closed", { source: "free_login_gift_dialog" });
				}}
				onClaimMyReward={() => {
					if (!authToken) {
						openLoginToContinueDialog();
					} else {
						handleClose();
					}
					reportEvent("claim_my_reward_click", {
						source: "free_login_gift_dialog"
					});
				}}
			/>
			<DialogLoginToContinue
				show={myVariant === WsDialogs.LOGIN_TO_CONNECT}
				// TODO: review this piece of code
				/* eslint-disable-next-line */
				onCloseClick={(event: any, reason: any) => {
					debug.logFunction({
						iconText: "DialogLoginToContinue",
						message: ["-> onCloseClick:", "event, reason", event, reason]
					});

					!event?.keepCurrentOffer && setCurrentOffer(null);

					handleClose();
					reportEvent("dialog_x_closed", {
						source: "login_to_continue_dialog"
					});
				}}
			/>
			<DialogYourAccountIsNotConnected
				show={myVariant === WsDialogs.ACCOUNT_NOT_CONNECTED}
				onCloseClick={() => {
					handleClose();
					removeScopelyStateSession();
					reportEvent("dialog_x_closed", {
						source: "account_not_connected_dialog"
					});
				}}
				onConnect={() => {
					removeScopelyStateSession();
					openDialog(WsDialogs.LOGIN_TO_CONNECT);

					reportEvent("connect_clicked", {
						source: "account_not_connected_dialog"
					});
				}}
			/>
			<DialogDailyGiftConfirmation
				show={myVariant === WsDialogs.DAILY_GIFT_CONFIRMATION}
				onCloseClick={() => {
					handleClose();
					reportEvent("dialog_x_closed", {
						source: "daily_gift_confirmation_dialog"
					});
				}}
			/>
			<DialogLoginRewardConfirmationV2
				show={myVariant === WsDialogs.LOGIN_REWARD_CONFIRMATION}
				onCloseClick={() => {
					handleClose();
					reportEvent("dialog_x_closed", {
						source: "received_first_login_reward_confirmation_dialog"
					});
				}}
			/>
			<DialogXsollaPurchase
				show={myVariant === WsDialogs.XSOLLA_PURCHASE}
				onReturnToShopClick={() => {
					reportEvent("dialog_complete_click", { source: "xsolla_dialog" });
					handleRefresh();
					handleClose();
				}}
				onCloseClick={() => {
					reportEvent("dialog_x_closed", { source: "xsolla_dialog" });
					handleRefresh();
					handleClose();
				}}
			/>
			<DialogLoyaltyPurchaseConfirm
				show={myVariant === WsDialogs.LOYALTY_PURCHASE_CONFIRM}
				onCloseClick={handleClose}
			/>
			<DialogLoyaltyPurchaseSuccess
				show={myVariant === WsDialogs.LOYALTY_PURCHASE_SUCCESS}
				onCloseClick={handleClose}
			/>
			<DialogShopPurchaseSuccess
				show={myVariant === WsDialogs.SHOP_PURCHASE_SUCCESS}
				onCloseClick={handleClose}
			/>
			<DialogErrorInsufficientLoyaltyPointBalance
				show={myVariant === WsDialogs.INSUFFICIENT_LOYALTY_POINT_BALANCE}
				onCloseClick={handleClose}
			/>
			<DialogLoyaltyFaq
				show={myVariant === WsDialogs.LOYALTY_FAQ}
				onCloseClick={handleClose}
			/>
		</>
	);
};

export default AppModalDialogs;
