import styled from "styled-components";
import { tabletGiftsGaloreView } from "pages/ClubShop/ClubEventSection/lib/GiftsGaloreCard/styles";

export const Container = styled("div")<{ isMobile: boolean; background: string; }>(({ isMobile, background }) => `
	background-color: #fff;
	padding: 1rem;
	width: 80vw;
	margin: 0 auto;
	background-image: url(${background});
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: ${isMobile ? "center bottom" : "center center"};
	height: 512px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 40%;
	border-radius: 1rem;
	overflow: hidden;

	@media (max-width: 932px) and (max-height: 440px) {
		background-position: center center;
	}
	@media (min-width: 365px) {
		width: 320px;
		gap: 35%;
	}
	@media (min-width: ${tabletGiftsGaloreView}) {
		width: 90vw;
		height: 300px;
	}
	@media (min-width: 920px) {
		gap: 25%;
		width: 840px;
		height: 330px;
	}
	@media (max-height: 360px) {
		height: 200px;
		background-size: 105% 125%;
		gap: 0;
	}
`);

export const Head = styled("div")`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.img`
	max-width: 250px;

	@media (min-width: ${tabletGiftsGaloreView}) {
		max-width: 80%;
	}
`;
