import { useAppDispatch, useImagePath } from "hooks";
import { onClosePopUp } from "redux/slices";
import { Container, Header, Avatar, Body, Button, Title } from "./styles";
import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import BackToGameButton from "components/Buttons/BackToGameButton";

interface GrantRewardCollectedProps { }

export const GrantRewardCollected: React.FC<GrantRewardCollectedProps> = () => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const headImageUrl = getImage("/spec_dips/background.png");
	const avatarUrl = getImage("/spec_dips/pois.png");

	return (
		<Container>
			<Header data-image={headImageUrl}>
				<Avatar src={avatarUrl} />
			</Header>
			<Body>
				<Title>Your Reward was sent to your TriPeaks Inbox</Title>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={isMobile ? 6 : 12} display="flex" justifyContent="center">
						<Button
							onClick={() => dispatch(onClosePopUp())}
							sx={{
								minWidth: { xs: "80%", sm: isMobile ? "100%" : "80%" },
								lineHeight: 1.2
							}}
						>
							Continue Shopping
						</Button>
					</Grid>
					{isMobile && (
						<Grid item xs={12} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="grant_reward_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "80%", sm: "100%" } }}
							/>
						</Grid>
					)}
				</Grid>
			</Body>
		</Container>
	);
};
