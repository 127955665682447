import { Box, styled } from "@mui/material";
import { images } from "helpers";

const imageSrc = images.spec_landing_page__page_content_background.src;

export const PageContentContainer = styled("div")`
	display: flex;
	justify-content: center;
	background-repeat: no-repeat, no-repeat;
	background-size: 100% auto;
	background-position: center 69vw;
	//min-height: 28vh;
	background-color: #fffaf1;
	//background-image: url(${imageSrc});
	//background-repeat: repeat-y;
	position: relative;
	padding-bottom: 9rem;
`;
export const PageContentInner = styled(Box)({
	flexDirection: "column",
	width: "calc(100% - 2rem)",
	padding: "0 1rem"
});
