import { useEffect } from "react";
import { useAppDispatch, useAppSelector, useImagePath } from "hooks";
import { onClosePopUp, onPBV2change, onPBchange, selectPoisBountyState, selectPoisBountyV2State } from "redux/slices";
import { Container, Title, Description, Button } from "./styles";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { FeaturesFlags, links } from "helpers";
import titleV2 from "assets/pois-bounty/v2/pois-logo.png";
import { useEnvContext } from "contexts";
import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import BackToGameButton from "components/Buttons/BackToGameButton";

interface PBUncollectedRewardsProps { }

export const PBUncollectedRewards: React.FC<PBUncollectedRewardsProps> = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const { getImage } = useImagePath("local");
	const { isFeatureFlagEnabled } = useEnvContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { tracker } = useAppSelector(selectPoisBountyState).data;
	const { trackers } = useAppSelector(selectPoisBountyV2State);
	const title = isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2)
		? titleV2
		: getImage("/spec_pois_bounty/pois_bounty_logo_text.png");
	const backgroundEmblem = getImage(
		"/spec_pois_bounty/pois_bounty_tiki_and_poi_emblem.png"
	);

	useEffect(() => {
		reportEvent("dialog_shown", {
			source: "pois_bounty_login_collect",
			pois_bounty_id: trackers.eventId ?? tracker.eventId
		});

		return () => {
			dispatch(onPBchange({ isEventActive: false }));
			dispatch(onPBV2change({ isEventActiveV2: false }));
			// if the user is on the /pois-bounty page we redirect to landing page
			if (pathname === links.pois_bounty.path) {
				navigate(links.home.path);
			}
		};
	}, []);

	return (
		<Container background-image={backgroundEmblem}>
			<Title src={title} />
			<Description>
				Your uncollected rewards during this event were sent to your in-game inbox
			</Description>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={isMobile ? 6 : 12} display="flex" justifyContent="center">
					<Button
						onClick={() => {
							dispatch(onClosePopUp());
							reportEvent("collect_clicked", {
								source: "pois_bounty_login_collect",
								pois_bounty_id: trackers.eventId ?? tracker.eventId
							});
						}}
						sx={{ minWidth: { xs: "80%", sm: isMobile ? "100%" : "80%" } }}
					>
						Continue Shopping
					</Button>
				</Grid>
				<Grid item xs={12} sm={6} display="flex" justifyContent="center">
					{isMobile && (
						<BackToGameButton
							source="pois_bounty_login_collect_dialog"
							themeColor="secondary"
							sx={{ minWidth: { xs: "80%", sm: "100%" } }}
						/>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};
