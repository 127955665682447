import { Grid } from "@mui/material";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { IShopRewardItem } from "contexts/WebStoreProvider/types";
import { getCount, getDisplay } from "helpers";
import useBreakpoints from "hooks/useBreakpoints";
import { Node } from "interfaces/drip-offer";
import { Moment } from "moment";
import { getAvailablePrizes, getMissedDays, getNodeItemData } from "pages/DripOffer/hooks";
import { RedX } from "pages/DripOffer/styles";
import { useEffect } from "react";
import { RedXIcon } from "utils/svgIcons";

interface SaleInfoProps {
	offerAvailableDays: number;
	nodes: Node[];
	localTime: string;
	nowTime: Moment | null;
	endTime: string;
	isPopup?: boolean;
}

export const SaleInfo = ({ offerAvailableDays, nodes, localTime, isPopup, nowTime, endTime }: SaleInfoProps) => {

	const { isSmDown } = useBreakpoints();
	const { store } = useWebStoreContext();
	const { reportEvent } = useGoogleAnalyticsContext();

	const missedDays = getMissedDays(nodes, localTime);

	useEffect(() => {
		if (store) {
			const timeRemaining = getCount(nowTime, endTime);
			const timeToDisplay = getDisplay(timeRemaining);
			const furthestNode = nodes.findLast((n: Node) => n.state === "claimed");
			const currentNode = nodes.findLast((n: Node) => n.state === "available");
			const useNode = currentNode ?? furthestNode ?? nodes[0];
			const rewardType: IShopRewardItem[] | null = getNodeItemData(store, useNode);

			reportEvent("feature_rendered", {
				source: "drip_offer",
				furthest_prize_reward_id: furthestNode?.rewardId,
				total_Xs: missedDays,
				location: isPopup ? "drip_offer_collect_popup" : "special_offer_section",
				time_to_claim: timeToDisplay,
				reward_type: `${rewardType?.[0].id}_${rewardType?.[0].value}`,
				claimed: useNode?.state === "claimed" ?? false,
			});
		}
	}, [nodes]);

	if (isSmDown || isPopup) {
		return (
			<Grid item xs={8} zIndex={1} mx="auto" position="relative" height={isPopup ? "100%" : "auto"}>
				<Grid container className="sale-info" alignItems="center" justifyContent="center">
					<Grid item xs={6.2} pl={.5}>
						<p className="title">MISSED DAYS</p>
					</Grid>
					<Grid item xs={5.8}>
						<Grid container direction="row-reverse">
							{Array.from(Array(offerAvailableDays)).map((_, i) => (
								<Grid item xs={4} key={i} className="red-x">
									{i < 3 - missedDays ? <span /> : <RedX><RedXIcon /></RedX>}
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid item xs={6.2} pl={.5}>
						<p className="title">PRIZES LEFT</p>
					</Grid>
					<Grid item xs={5.8} pt={{ xs: .5, sm: .4, md: .5 }}>
						<p>{getAvailablePrizes(nodes)}</p>
					</Grid>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Grid item minWidth={360} zIndex={1} mx="auto" mb={3} position="relative">
				<Grid container className="sale-info">
					<Grid item sm={6} mt={.5} mb={1}>
						<p>PRIZES LEFT</p>
					</Grid>
					<Grid item sm={6} mt={.5} mb={1}>
						<p>MISSED DAYS</p>
					</Grid>
					<Grid item sm={6} pl={.5} display="flex" alignItems="center" justifyContent="center">
						<p>{getAvailablePrizes(nodes)}</p>
					</Grid>
					<Grid item sm={6}>
						<Grid container direction="row-reverse" justifyContent="center">
							{Array.from(Array(offerAvailableDays)).map((_, i) => (
								<Grid item key={i} mx={.2} className="red-x">
									{i < 3 - missedDays ? <span /> : <RedX><RedXIcon /></RedX>}
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
};
