import { useEffect } from "react";
import classNames from "classnames";
import { useAppDispatch } from "hooks";
import { onClosePopUp } from "redux/slices";
import { useWebStoreContext } from "contexts";
import CTA from "components/Buttons/ThemeButton";
import useBreakpoints from "hooks/useBreakpoints";
import { Background, Body, Container, Head, Note } from "./styles";
import background from "assets/loyalty-points/background-added.png";
import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import BackToGameButton from "components/Buttons/BackToGameButton";

interface LoyaltyPointsAddedProps { }

export const LoyaltyPointsAdded: React.FC<LoyaltyPointsAddedProps> = () => {
	const dispatch = useAppDispatch();
	const { isSmDown } = useBreakpoints();
	const { syncUserTracker } = useWebStoreContext();

	useEffect(() => {
		syncUserTracker();
	}, []);

	return (
		<Container
			className={classNames([isSmDown && "mobile", !isSmDown && "landscape"])}
		>
			<Head>
				<Background src={background} />
			</Head>
			<Body>
				<Note className="note">
					Your Loyalty Points have been added to your balance!
				</Note>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={isMobile ? 6 : 12} display="flex" justifyContent="center">
						<CTA
							sx={{
								minWidth: { xs: "95%", sm: isMobile ? "100%" : "95%" },
								lineHeight: 1.2
							}}
							onClick={() => {
								dispatch(onClosePopUp());
							}}
						>
							Continue Shopping
						</CTA>
					</Grid>
					{isMobile && (
						<Grid item xs={12} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="loyalty_points_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "95%", sm: "100%" } }}
							/>
						</Grid>
					)}
				</Grid>
			</Body>
		</Container>
	);
};
