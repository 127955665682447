import { DateTime } from "luxon";
import moment, { Duration, Moment } from "moment";

interface ICount {
	totalDays: number;
	totalSeconds: number;
	years: number;
	months: number;
	weeks: number;
	days: number;
	hrs: number;
	mins: number;
	secs: number;
}

export const pad = (n: number) => {
	return String(n).padStart(2, "0");
};

export const getCount = (nowTime: Moment | null, endTime: string): ICount => {
	if (nowTime == null) {
		return {
			totalDays: 0,
			totalSeconds: 0,
			years: 0,
			months: 0,
			weeks: 0,
			days: 0,
			hrs: 0,
			mins: 0,
			secs: 0
		};
	}
	const endTimeMoment = moment(endTime);
	const diffTime = endTimeMoment.diff(nowTime);
	const duration: Duration = moment.duration(diffTime);
	return {
		totalDays: Math.floor(duration.asDays()),
		totalSeconds: Math.floor(duration.asSeconds()),
		years: duration.years(),
		months: duration.months(),
		weeks: duration.weeks(),
		days: duration.days(),
		hrs: duration.hours(),
		mins: duration.minutes(),
		secs: duration.seconds()
	};
};

export const getDisplay = (count: ICount) => {
	const { years, months, weeks, days, hrs, mins, secs } = count;
	if (secs < 0) {
		return "";
	}

	let newDisplay = "";

	switch (true) {
		// TODO: add "fall through" comments to pass linter
		case !!years: // fall through
		case !!months: // fall through
		case !!weeks:
		case !!days:
			// | "6d 23h 59m"
			newDisplay = `${days}d ${hrs}h ${pad(mins)}m`;
			break;

		case !!hrs:
			// | "23h 59m 59s"
			newDisplay = `${hrs}h ${mins}m ${pad(secs)}s`;
			break;

		case !!mins:
			// | "59m 59s"
			newDisplay = `${mins}m ${pad(secs)}s`;
			break;

		case !!secs: // fall through
		default:
			// | "0m 59s"
			newDisplay = `${secs}s`;
	}
	return newDisplay;
};

export const getLocalDateEtcGMTFormat = () => {
	const offset = DateTime.local().offset / 60;
	return `Etc/GMT${offset}`;
};
