import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts";
import { nFormatter } from "utils/number-formatter";
import background from "assets/pois-bounty/bg-pop-up.jpg";
import { useAppDispatch, useAppSelector, useImagePath } from "hooks";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { onClosePopUp, onPBV2change, onPBchange, selectPoisBountyState, selectPoisBountyV2State } from "redux/slices";
import {
	Container,
	Header,
	Reward,
	Icon,
	RewardImg,
	RewardText,
	Background,
	Body,
	Button,
	Title
} from "./styles";
import { Grid } from "@mui/material";
import BackToGameButton from "components/Buttons/BackToGameButton";
import { isMobile } from "react-device-detect";

interface PBRewardCollectedProps { }

export const PBRewardCollected: React.FC<PBRewardCollectedProps> = () => {
	const dispatch = useAppDispatch();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { getImage } = useImagePath("cdn");
	const { getImage: getImageLocal } = useImagePath("local");
	const { rewardCollected, data } = useAppSelector(selectPoisBountyState);
	const { rewardCollectedV2, trackers } = useAppSelector(selectPoisBountyV2State);
	const { tracker } = data;

	return (
		<Container>
			<Header>
				<Background src={background} />
				<Reward
					data-image={getImage(
						"/spec_pois_bounty/pois_bounty_reward_background.png"
					)}
				>
					<Icon>
						<img
							src={getImageLocal(
								"/spec_pois_bounty/pois_bounty_icon_check.png"
							)}
						/>
					</Icon>
					<RewardImg src={rewardCollectedV2?.imageUrl ?? rewardCollected?.imageUrl} />
					{(rewardCollectedV2?.rewardAmount || rewardCollected?.rewardAmount) && (
						<RewardText>
							x{nFormatter(Number(rewardCollectedV2?.rewardAmount ?? rewardCollected?.rewardAmount))}
						</RewardText>
					)}
				</Reward>
			</Header>
			<Body>
				<Title>
					Your reward items are waiting in your in-game inbox to be collected.
				</Title>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={isMobile ? 6 : 12} display="flex" justifyContent="center">
						<Button
							onClick={() => {
								dispatch(onClosePopUp());
								// analytics only for v1
								if (isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY)) {
									reportEvent("reward_claimed_popup_clicked", {
										source: "pois_bounty_menu",
										pois_bounty_id: trackers.eventId ?? tracker.eventId
									});
								}
								dispatch(onPBchange({ rewardCollected: undefined }));
								dispatch(onPBV2change({ rewardCollectedV2: undefined }));
							}}
							sx={{ minWidth: { xs: "80%", sm: isMobile ? "100%" : "80%" } }}
						>
							Continue
						</Button>
					</Grid>
					{isMobile && (
						<Grid item xs={12} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="pois_bounty_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "80%", sm: "100%" } }}
							/>
						</Grid>
					)}
				</Grid>
			</Body>
		</Container>
	);
};
