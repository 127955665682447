import { SxProps } from "@mui/material";
import BackToTopButton from "components/PageContent/lib/PageBackToTopButton";
import { ReactNode } from "react";
import { PageContentContainer, PageContentInner } from "./styled";

export interface IPageContentProps {
	children: ReactNode;
	sxContainer?: SxProps;
	sxContent?: SxProps;
	enableBackToTop?: boolean;
	// analytics source
	source?: string;
}

export const PageContent = ({
	children,
	sxContainer = {},
	sxContent = {},
	enableBackToTop = false,
	source = ""
}: IPageContentProps) => (
	<PageContentContainer className="page-container" sx={sxContainer}>
		<PageContentInner
			className="page-content"
			sx={{
				maxWidth: { lg: "1245px" },
				...sxContent
			}}
		>
			{children}
			{enableBackToTop && <BackToTopButton source={source} />}
		</PageContentInner>
	</PageContentContainer>
);
