import { Grid } from "@mui/material";
import ThemeButton from "components/Buttons/ThemeButton";
import styled, { CSSProperties } from "styled-components";
import { blink, shine } from "./animations";

export const DripOfferContainer = styled.div<{ largeTicket: string }>`
	h3 {
		color: #fff;
		font-family: "Baloo";
		font-size: 20px;
		margin: .5rem 0;
		text-shadow: 1px 2px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
			1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
			1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
			-1px -1px 0 #000;

		&.mobile {
			color: #5d2904;
			text-align: center;
			font-size: 16px;
			line-height: 16px;
			margin: .1rem 0 .2rem;
		}

		&.isPopup {
			@media (orientation: portrait) {
				color: #5d2904;
				text-shadow: none;
			}
		}

		@media (max-width: 599px) {
			font-size: 16px;
			text-shadow: none;
		}
		@media (max-width: 430px) {
			font-size: 15px;
		}
		@media (max-width: 360px) {
			font-size: 14px;
		}
	}

	.ticket {
		background-image: url(${(props) => props.largeTicket});
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		padding: 18px 62px;

		.item-background {
			position: absolute;
			border-radius: 16px;
			opacity: 0.2;
			background: #d98883;
			width: 100%;
			height: 100%;
		}

		@media (max-width: 599px) {
			padding: 16px 22px;
		}
	}

	.item-content {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		&.small {
			height: 130px;

			img {
				max-height: 50%;
				max-width: 85%;
			}
		}

		&.large {
			height: 90px;
			img {
				height: 75%;
			}
		}

		p {
			position: absolute;
			right: 0;
			color: #fff;
			font-family: "Baloo";
			font-size: 20px;
			line-height: 20px;
			margin: 0;
			text-shadow: 1px 2px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
				1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
				1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
				-1px -1px 0 #000;

			&.small {
				bottom: 15%;
			}

			&.large {
				bottom: 10%;
				right: 0;
			}

			&.is-coin {
				right: 50%;
				transform: translateX(50%);
			}
		}
	}

	.plus {
		font-family: "Baloo";
		font-size: 61px;
		color: white;
		text-shadow: 1px 3px 0 #000, -3px 3px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000, 0px 3px 0 #000, 0px -3px 0 #000, -3px 0px 0 #000,
			3px 0px 0 #000, 3px 3px 0 #000, -3px 1px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000, 0px 3px 0 #000, 0px -3px 0 #000, -3px 0px 0 #000,
			3px 0px 0 #000, 3px 3px 0 #000, -3px 3px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000, 3px 3px 0 #000, -3px 1px 0 #000, 3px -3px 0 #000,
			-3px -3px 0 #000;
	}

	.sale-info {
		height: 100%;
		background-image: url(${(props) => props.largeTicket});
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		padding: 50px 10px 19px 10px;

		p {
			text-align: center;
			color: #f8f3e4;
			font-family: "Baloo";
			font-size: 16px;
			line-height: 16px;
			margin: 0;

			@media (max-width: 599px) {
				&.title {
					color: #5d2904;
					text-align: left;
					line-height: 79%;
				}
			}
		}

		span {
			padding: 0 12px;

			@media (max-width: 599px) {
				padding: 0 10px;
			}
		}

		.red-x {
			width: 24px;
			height: 23px;
		}

		@media (max-width: 599px) {
			padding: 57px 17px 16px;
		}
	}
`;

export const RedX = styled.svg`
	animation: ${blink} 0.5s infinite;
	animation-iteration-count: 3;
	width: 100%;
	height: 100%;
`;

export const SmallTicket = styled(Grid)<{
	background: string;
	claimed: string;
	ispopup?: string;
}>`
	background-image: url(${(props) => props.background});
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
	opacity: ${(props) => (props.claimed === "true" ? 0.5 : 1)};

	p {
		margin: 0;
		height: ${(props) => (props.ispopup === "true" ? "25px" : "30px")};

		&.prize-name {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #5d2904;
			text-align: center;
			font-family: "Baloo";
			font-size: ${(props) => (props.ispopup === "true" ? "14px" : "16px")};
			line-height: ${(props) => (props.ispopup === "true" ? "12px" : "16px")};
			text-transform: capitalize;
		}
	}
`;

export const ClaimedSmallTicket = styled.img`
	position: absolute;
	top: 45%;
	left: 54%;
	transform: translate(-50%, -50%);
	width: 80%;
	z-index: 1;
`;

export const Shine = styled.img`
	position: absolute;
	top: 45%;
	left: 54%;
	width: 140%;
	opacity: 0.8;
	animation: ${shine} 60s linear infinite;
`;

export const Background = styled.div<{ background: string }>`
	position: absolute;
	top: 0;
	left: 0;
	background-image: url(${(props) => props.background});
	background-position: center center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100vw;
	height: 80vh;
	z-index: 0;

	@media (orientation: landscape) {
		@media (max-width: 1366px) {
			background-size: cover;
			height: 70vh;
		}
		@media (max-width: 1200px) {
			height: 80vh;
		}
		@media (max-width: 1180px) {
			height: 87vh;
		}
		@media (max-width: 1024px) {
			height: 93vh;
		}
		@media (max-height: 599px) {
			height: 165vh;
		}
		@media (max-height: 390px) {
			height: 185vh;
		}
		@media (max-height: 375px) {
			height: 210vh;
		}
		@media (max-height: 265px) {
			height: 215vh;
		}
	}
	@media (orientation: portrait) {
		@media (max-width: 1024px) {
			background-size: cover;
			height: 52vh;
		}
		@media (max-width: 820px) {
			height: 60vh;
		}
		@media (max-width: 768px) {
			height: 70vh;
		}
		@media (max-width: 599px) {
			background-size: 100% 100%;
			height: 76vh;
		}
		@media (max-height: 896px) {
			height: 82vh;
		}
		@media (max-height: 740px) {
			height: 95vh;
		}
		@media (max-height: 667px) {
			height: 100vh;
		}
	}
`;

export const Title = styled.img`
	max-width: 414px;
	width: 100%;

	@media (max-width: 680px) {
		margin-top: 60px;
	}
`;

export const Button = styled(ThemeButton)`
	max-width: 329px;
	width: 100%;
	font-size: 24px;
`;

export const InfoButton = styled.button`
	position: absolute;
	top: 7%;
	right: 0;
	background: none;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&.is-small {
		top: 95%;
		right: 100%;
		transform: translate(95%, -100%);
	}

	&.is-popup {
		right: -8px;
	}
`;

export const Tiki = styled.img<{ isPopup?: boolean }>`
	position: absolute;
	top: ${(props) => (props.isPopup ? "-62px" : "-95px")};
	right: 0;
	width: ${(props) => (props.isPopup ? "95px" : "144px")};

	@media (max-width: 599px) {
		transform: rotateY(180deg);
		left: ${(props) => (props.isPopup ? "77%" : "40px")};
	}
`;

export const SmallTicketTiki = styled.img`
	position: absolute;
	top: -47px;
	right: 50%;
	transform: translateX(50%);
	width: 83px;
	z-index: 1;
`;

export const timerStyle: CSSProperties = {
	position: "absolute",
	top: 0,
	left: 0
};

export const timerStyleMobile: CSSProperties = {
	...timerStyle,
	top: "5%",
	whiteSpace: "nowrap"
};

export const LoyaltyPoints = styled.div`
	border-radius: 15px;
	background: rgba(0, 0, 0, 0.5);
	font-family: "Inter";
	font-size: 14px;
	font-weight: 700;
	line-height: 14px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3px;
	width: 133px;
	height: 24px;
`;
