import styled from "styled-components";

export const defaultTextColor = "#073670";

export const SectionTitle = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: ${defaultTextColor};
	display: flex;
	align-items: center;
	margin: 10px 0px 0px 0px;
	font-family: Baloo;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;

	@media (min-width: 550px) {
		font-size: 34px;
	}
`;

export const SectionIconContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 5px;
`;

export const CardFinalMessage = styled.div`
	color: ${(props) => props.color || defaultTextColor};
	font-weight: 700;
	font-size: 0.92em;
	line-height: 1em;
	text-align: center;

	&.with-contrast {
		text-shadow: 0px 0px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
			-1px -1px 0 #474747, 0px 1px 0 #474747, 0px -1px 0 #474747,
			-1px 0px 0 #474747, 1px 0px 0 #474747, 1px 1px 0 #474747,
			-1px 1px 0 #474747, 1px -1px 0 #474747, -1px -1px 0 #474747,
			0px 1px 0 #474747, 0px -1px 0 #474747, -1px 0px 0 #474747,
			1px 0px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747,
			1px -1px 0 #474747, -1px -1px 0 #474747, 1px 1px 0 #474747,
			-1px 1px 0 #474747, 1px -1px 0 #474747, -1px -1px 0 #474747;

		@media (max-width: 430px) {
			font-size: 14px;
		}
		@media (max-width: 414px) {
			font-size: 13px;
		}
		@media (max-width: 390px) {
			font-size: 12px;
		}
		@media (max-width: 375px) {
			font-size: 10px;
		}
	}

	&.mt {
		margin-top: 0.6em;
	}

	&.mb {
		margin-bottom: 0.6em;
	}

	@media (min-width: 576px) {
		font-size: 1em;
	}

	@media (max-width: 932px) and (max-height: 430px) {
		font-size: 0.8em;
	}
`;
