import {} from "hooks";
import useDebugAction from "hooks/useDebugAction";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";

export const DebugActions: React.FC = ({ children }) => {
	const {
		isLoading: isSettingDebugAction,
		isSuccess: isSuccessDebugAction,
		isError: isErrorDebugAction
	} = useDebugAction();
	const location = useLocation();

	if (isSettingDebugAction) {
		return (
			<div style={{ color: "blue", marginLeft: "5px" }}>
				Setting debug actions...
			</div>
		);
	}

	// If you prefer to prevent the site from reloading, simply comment the following:
	if (isSuccessDebugAction) {
		window.location.replace(location.pathname);
		return (
			<div style={{ color: "green", marginLeft: "5px" }}>
				Success - debug actions
			</div>
		);
	}

	if (isErrorDebugAction) {
		window.location.replace(location.pathname);
		return (
			<div style={{ color: "red", marginLeft: "5px" }}>
				Error - debug actions
			</div>
		);
	}

	return <Fragment>{children}</Fragment>;
};
