import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useScroll } from "../../../../hooks/useScroll";
import { Stack } from "@mui/material";
import { SectionMenu as SectionMenuComponent } from "../../styled";
import { SectionMenuItem } from "./lib/SectionMenuItem";
import { ISectionMenuItem } from "components/PageTopNav/types";
import { useGetFeatureTrackerQuery } from "redux/apis";
import { useEnvContext, useWebStoreContext } from "contexts";
import { FeaturesFlags } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectStickyDIPState } from "redux/slices";
import { postUserTrackerValue } from "redux/slices/common";
import useNewDay from "hooks/useNewDay";

// Restrict value to be between the range [0, value]
function clamp(value: number) {
	return Math.max(0, value);
}
// Check if number is between two values
function isBetween(value: number, floor: number, ceil: number) {
	return value >= floor && value <= ceil;
}

export const SectionMenu = ({ items }: { items: ISectionMenuItem[] }) => {
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const [sectionMenuBottomPosition, setSectionMenuBottomPosition] = useState<
		number | null
	>(null);
	const [selectedRef, setSelectedRef] = useState<
		HTMLDivElement | null | undefined
	>(null);
	const [displayLimitedDealDot, setDisplayLimitedDealDot] =
		useState<boolean>(false);
	const scroll = useScroll();
	const dispatch = useAppDispatch();
	const sectionMenuRef = useRef<HTMLDivElement | null>(null);

	const updateSectionMenuBottomPosition = () => {
		const newSectionMenuBottomPosition =
			sectionMenuRef.current?.getBoundingClientRect().top &&
			sectionMenuRef.current?.getBoundingClientRect().top - 20;

		setSectionMenuBottomPosition(newSectionMenuBottomPosition || 0);
	};
	const { authToken, userTracker } = useWebStoreContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { data: stickyData } = useAppSelector(selectStickyDIPState);
	const { newDay } = useNewDay();
	const isWaterfallSaleActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.WATERFALL_SALES)
		);
	};

	const isEndlessOfferActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.ENDLESS_OFFER)
		);
	};

	const isStickyDipActive = () => {
		return isFeatureFlagEnabled(FeaturesFlags.STICKY_DIPS);
	};

	const { data: waterfallData, refetch: refetchWaterfall } =
		useGetFeatureTrackerQuery(
			{
				FeatureType: "waterfall-sale",
				isAuthenticated: !!authToken?.value
			},
			{ skip: !isWaterfallSaleActive() }
		);

	const { data: endlessOfferData, refetch: refetchEndlessOffer } =
		useGetFeatureTrackerQuery(
			{
				FeatureType: "endless-offer",
				isAuthenticated: !!authToken?.value
			},
			{ skip: !isEndlessOfferActive() }
		);

	const sawCurrentLimitedDealsSection = () => {
		dispatch(
			postUserTrackerValue({
				key: "lastSeenLimitedDealSection",
				value: isWaterfallSaleActive()
					? waterfallData?.sessionTracker?.id
					: isEndlessOfferActive()
					? endlessOfferData?.tracker?.id
					: stickyData?.offers?.[0]?.id
			})
		);
		setDisplayLimitedDealDot(false);
	};

	useLayoutEffect(() => {
		updateSectionMenuBottomPosition();
		const isInView = () => {
			const offset = 200;
			const scroll = window.pageYOffset;
			const position = items
				.map((item, index) => {
					const element = item.sectionRef?.current;
					if (!element) {
						return { index, top: -1, bottom: -1 };
					}
					const rect = element.getBoundingClientRect();
					const top = clamp(rect.top + scroll - offset);
					const bottom = clamp(rect.bottom + scroll - offset);
					return {
						index,
						top,
						bottom,
						ref: item.sectionRef?.current
					};
				})
				.find(({ top, bottom }) => isBetween(scroll, top, bottom));

			setSelectedIndex(() => {
				return position?.index || (position?.index === 0 ? 0 : null);
			});
		};
		// setTimeout used so ref.current populates on initial load
		setTimeout(() => isInView(), 0);
	}, [scroll]);

	useEffect(() => {
		if (isWaterfallSaleActive()) {
			setDisplayLimitedDealDot(
				waterfallData?.sessionTracker?.id !==
					userTracker?.metadata?.lastSeenLimitedDealSection
			);
		} else if (isEndlessOfferActive()) {
			setDisplayLimitedDealDot(
				endlessOfferData?.tracker?.id !==
					userTracker?.metadata?.lastSeenLimitedDealSection
			);
		} else if (isStickyDipActive()) {
			setDisplayLimitedDealDot(
				stickyData?.offers?.[0]?.id !==
					userTracker?.metadata?.lastSeenLimitedDealSection
			);
		}
	}, [userTracker, waterfallData, endlessOfferData, stickyData]);

	// TODO: hoizontally scroll to selected button item
	useEffect(() => {
		const currentItemCords = selectedRef?.getBoundingClientRect();
		if (currentItemCords) {
			const { /*right,*/ left } = currentItemCords;
			if (left < 0) {
				if (typeof sectionMenuRef?.current?.scrollLeft === "number") {
					sectionMenuRef.current.scrollLeft -= -50; // left;
				}
			}
		}
	}, [selectedRef]);

	useEffect(() => {
		if (newDay) {
			refetchWaterfall();
			refetchEndlessOffer();
		}
	}, [newDay]);

	return items.length > 0 ? (
		<SectionMenuComponent ref={sectionMenuRef}>
			<Stack direction="row" spacing={0.1} pt={{ xs: "1px", sm: 0 }}>
				{items.map((item, index) => {
					if (!item.sectionRef?.current) {
						return;
					}

					if (item.text === "Limited Deals") {
						if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
							if (item.name !== "waterfall_sale") {
								return;
							}
						} else if (isEndlessOfferActive() && endlessOfferData?.tracker) {
							if (item.name !== "endless_offer") {
								return;
							}
						} else if (isStickyDipActive() && stickyData) {
							if (item.name !== "limited_deals") {
								return;
							}
						} else {
							return;
						}
					}

					return (
						<SectionMenuItem
							item={item}
							index={index}
							selectedIndex={selectedIndex}
							setSelectedIndex={setSelectedIndex}
							key={item.name}
							updateSectionMenuBottomPosition={updateSectionMenuBottomPosition}
							sectionMenuBottomPosition={sectionMenuBottomPosition}
							setSelectedRef={setSelectedRef}
							displayLimitedDealDot={displayLimitedDealDot}
							sawCurrentLimitedDealsSection={sawCurrentLimitedDealsSection}
						/>
					);
				})}
			</Stack>
		</SectionMenuComponent>
	) : null;
};
