/* eslint-disable @typescript-eslint/no-unused-vars */
import CountdownV2 from "components/CountdownV2";
import SectionHeader from "components/Section/SectionHeader";
import { Fragment, useEffect, useRef, useState } from "react";
import { LimitedDealsIcon } from "utils/svgIcons";
import {
	Background,
	BgBrush,
	Container,
	NodesContainer,
	NodesContainerSixPortrait,
	NodesContainerThreePortrait,
	PalmTrees,
	Rope,
	RopeSixPortrait,
	RopeThreePortrait,
	Title,
	timerStyle,
	timerStyleMobile
} from "./styles";
import bg from "assets/endless-offer/bg.png";
import bgMobile from "assets/endless-offer/bg-mobile.png";
import bgBrush from "assets/limited-deals/bg-brush.png";
import bgBrushMobile from "assets/limited-deals/bg-brush-mobile.png";
import bgPalmTrees from "assets/endless-offer/bg-palm-trees.png";
import bgPalmTreesMobile from "assets/endless-offer/bg-palm-trees-mobile.png";
import rope from "assets/endless-offer/rope.png";
import ropeSix from "assets/endless-offer/rope-six.png";
import { ReactComponent as ClockIcon } from "assets/clock.svg";
import { useMediaQuery } from "@mui/material";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { useGetFeatureTrackerQuery, useGetOffersQuery } from "redux/apis";
import {
	EndlessOfferNode,
	EndlessOfferResponse
} from "interfaces/endless-offer";
import { PurchaseOfferSingleItem } from "interfaces";
import { TransitionGroup } from "react-transition-group";
import { getBackgroundUrl, getClassByNodesCount } from "./helpers";
import { EndlessCrab } from "./lib/EndlessCrab";
import EndlessNode from "./lib/EndlessNode";
import {
	StyledCollapseHorizontal,
	StyledCollapseVertical
} from "./lib/EndlessNode/styles";
import { useTransition, animated } from "@react-spring/web";
import { getCount, getDisplay } from "helpers";

const EnlessOffer = () => {
	const isPortrait = useMediaQuery("(max-width:732px)");
	const containerRef = useRef<HTMLDivElement>(null);
	const [containerDimensions, setContainerDimensions] = useState<{
		height: number;
		width: number;
	}>({ height: 0, width: 0 });
	const { nowTime } = useWebStoreContext();
	const { authToken } = useWebStoreContext();
	const { data: purchaseOffers, isLoading: isOffersLoading } =
		useGetOffersQuery({
			offerType: "singleItem",
			isAuthenticated: !!authToken?.value
		});

	const {
		data: endlessOfferData,
		isLoading,
		isFetching,
		refetch
	}: {
		data?: EndlessOfferResponse;
		isLoading: boolean;
		isFetching: boolean;
		refetch: () => void;
	} = useGetFeatureTrackerQuery({
		FeatureType: "endless-offer",
		isAuthenticated: !!authToken?.value
	});

	const { reportEvent } = useGoogleAnalyticsContext();

	const handleResize = () => {
		if (containerRef?.current) {
			setContainerDimensions({
				height: containerRef.current?.clientHeight,
				width: containerRef.current?.clientWidth
			});
		}
	};

	const transitions = useTransition(
		endlessOfferData?.tracker?.nodes
			.filter((node) => !node.completionTime)
			.slice(
				0,
				endlessOfferData?.tracker?.displayNodeCount === 3
					? 4
					: endlessOfferData?.tracker?.displayNodeCount
			),
		{
			key: (node: EndlessOfferNode) => node?.nodeIndex,
			from: { opacity: 0 },
			enter: { opacity: 1, config: { duration: 1000 } },
			leave: { opacity: 0 },
			exitBeforeEnter: true
		}
	);

	const handleEnd = () => {
		setTimeout(() => {
			refetch();
		}, 1000);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [
		isPortrait,
		containerRef.current?.clientHeight,
		containerRef.current?.clientWidth
	]);

	useEffect(() => {
		if (endlessOfferData) {
			const filteredNodes = endlessOfferData?.tracker?.nodes.filter(
				(node) => !node.completionTime
			);
			const timeRemaining = getCount(
				nowTime,
				endlessOfferData?.tracker?.endTime
			);
			const timeToDisplay = getDisplay(timeRemaining);

			if (!filteredNodes) {
				return;
			}

			reportEvent("feature_rendered", {
				source: "endless_offer",
				reward_id: filteredNodes[0].rewardId,
				offer_id: filteredNodes[0].offerId,
				offer_price: purchaseOffers?.offers.find(
					(offer: PurchaseOfferSingleItem) =>
						offer.id === filteredNodes[0].offerId
				)?.price,
				node_type: filteredNodes[0].isSuperOffer ? "super" : "normal",
				node_index: filteredNodes[0].nodeIndex,
				configNodeCount: endlessOfferData?.tracker?.configNodeCount,
				nodeBadgeText: filteredNodes[0].nodeBadgeText,
				event_timer_remaining: timeToDisplay
			});
		}
	}, [endlessOfferData]);

	if (isLoading || isOffersLoading) {
		return <></>;
	}
	if (!endlessOfferData?.tracker) {
		return <></>;
	}

	const { tracker } = endlessOfferData;

	const getNextSuperOfferCount = () => {
		const filteredNodes = tracker?.nodes.filter((node) => !node.completionTime);
		return tracker.nextSuperOfferIndex - filteredNodes[0].nodeIndex;
	};

	return (
		<Fragment>
			<SectionHeader
				sx={{ marginBottom: { xs: "0px", sm: "10px" } }}
				text="LIMITED DEALS"
				icon={<LimitedDealsIcon />}
			/>
			<Container className="animate__animated animate__fadeInLeft">
				<Background
					imageUrl={getBackgroundUrl(
						tracker.themeBackgroundImageUrl,
						isPortrait
					)}
				/>
				<PalmTrees imageUrl={isPortrait ? bgPalmTreesMobile : bgPalmTrees} />
				<BgBrush src={isPortrait ? bgBrushMobile : bgBrush} />
				<CountdownV2
					icon={<ClockIcon />}
					endTime={tracker.endTime}
					style={isPortrait ? timerStyleMobile : timerStyle}
					startTime={nowTime}
					text="Offer ends in: "
					labelStyle={{ color: "#EB5757", marginLeft: 6 }}
					onEnd={() => {
						console.log("Countdown ended");
						handleEnd();
					}}
				/>
				<Title titleImage={tracker.titleImageUrl} />
				{isPortrait ? (
					tracker.displayNodeCount > 5 ? (
						<NodesContainerSixPortrait ref={containerRef}>
							{transitions(
								(style, node, key, index) =>
									node && (
										<animated.div
											style={{
												...style,
												gridColumn:
													([0, 3, 4].find((n) => n === index) && 1) ||
													([1, 2, 5].find((n) => n === index) && 2),
												gridRow:
													([0, 1].find((n) => n === index) && 1) ||
													([3, 2].find((n) => n === index) && 2) ||
													([4, 5].find((n) => n === index) && 3)
											}}
											key={node.nodeIndex}
										>
											<EndlessNode
												className={getClassByNodesCount(
													tracker.displayNodeCount
												)}
												node={node}
												offer={purchaseOffers?.offers.find(
													(offer: PurchaseOfferSingleItem) =>
														offer.id === node.offerId
												)}
												isFirstNode={index === 0}
												isFetching={isFetching}
												displayNodeCount={tracker.displayNodeCount}
												ribbonUrl={
													node.isSuperOffer
														? tracker.nodeSuperOfferBadgeRibbonImageUrl
														: tracker.nodeBadgeRibbonImageUrl
												}
												backgroundUrl={getBackgroundUrl(
													node.isSuperOffer
														? tracker.nodeSuperOfferBackgroundImageUrl
														: tracker.nodeBackgroundImageUrl,
													isPortrait
												)}
											/>
											{index === 0 && (
												<RopeSixPortrait
													className="first"
													imageUrl={ropeSix}
												></RopeSixPortrait>
											)}

											{[2, 5].find((n) => n === index) && (
												<RopeSixPortrait imageUrl={ropeSix}></RopeSixPortrait>
											)}
											{[3, 1].find((n) => n === index) && (
												<RopeSixPortrait
													className="vertical"
													imageUrl={ropeSix}
												></RopeSixPortrait>
											)}
										</animated.div>
									)
							)}
							;
						</NodesContainerSixPortrait>
					) : (
						<NodesContainerThreePortrait ref={containerRef}>
							<RopeThreePortrait src={rope} />
							<TransitionGroup
								style={{
									display: "flex",
									width: "100%",
									height: "100%",
									flexDirection: "column",
									justifyContent: "space-evenly"
								}}
							>
								{tracker.nodes
									.filter((node) => !node.completionTime)
									.slice(
										0,
										tracker.displayNodeCount === 3
											? 4
											: tracker.displayNodeCount
									)

									.map((node, index) => (
										<StyledCollapseVertical
											style={{
												width: "100%",
												height: "100% !important"
											}}
											orientation="vertical"
											key={node.nodeIndex}
											timeout={500}
										>
											<EndlessNode
												className={getClassByNodesCount(
													tracker.displayNodeCount
												)}
												node={node}
												offer={purchaseOffers?.offers.find(
													(offer: PurchaseOfferSingleItem) =>
														offer.id === node.offerId
												)}
												isFirstNode={index === 0}
												isFetching={isFetching}
												displayNodeCount={tracker.displayNodeCount}
												ribbonUrl={
													node.isSuperOffer
														? tracker.nodeSuperOfferBadgeRibbonImageUrl
														: tracker.nodeBadgeRibbonImageUrl
												}
												backgroundUrl={getBackgroundUrl(
													node.isSuperOffer
														? tracker.nodeSuperOfferBackgroundImageUrl
														: tracker.nodeBackgroundImageUrl,
													isPortrait
												)}
												containerHeight={containerDimensions.height}
											/>
										</StyledCollapseVertical>
									))}
							</TransitionGroup>
						</NodesContainerThreePortrait>
					)
				) : (
					<NodesContainer
						className={getClassByNodesCount(tracker.displayNodeCount)}
						ref={containerRef}
					>
						<Rope
							src={rope}
							className={getClassByNodesCount(tracker.displayNodeCount)}
						/>
						<TransitionGroup
							style={{
								display: "flex",
								width: "100%",
								height: "100%",
								justifyContent: "space-evenly"
							}}
						>
							{tracker.nodes
								.filter((node) => !node.completionTime)
								.slice(
									0,
									tracker.displayNodeCount === 3 ? 4 : tracker.displayNodeCount
								)

								.map((node, index) => (
									<StyledCollapseHorizontal
										style={{
											width: "100% !important",
											height: "100%"
										}}
										orientation="horizontal"
										key={node.nodeIndex}
										timeout={500}
									>
										<EndlessNode
											className={getClassByNodesCount(tracker.displayNodeCount)}
											key={index}
											node={node}
											offer={purchaseOffers?.offers.find(
												(offer: PurchaseOfferSingleItem) =>
													offer.id === node.offerId
											)}
											isFirstNode={index === 0}
											isFetching={isFetching}
											displayNodeCount={tracker.displayNodeCount}
											ribbonUrl={
												node.isSuperOffer
													? tracker.nodeSuperOfferBadgeRibbonImageUrl
													: tracker.nodeBadgeRibbonImageUrl
											}
											backgroundUrl={getBackgroundUrl(
												node.isSuperOffer
													? tracker.nodeSuperOfferBackgroundImageUrl
													: tracker.nodeBackgroundImageUrl,
												isPortrait
											)}
											containerWidth={containerDimensions.width}
										/>
									</StyledCollapseHorizontal>
								))}
						</TransitionGroup>
					</NodesContainer>
				)}
				{tracker.showCountdown && (
					<EndlessCrab nextSuperOfferCount={getNextSuperOfferCount()} />
				)}
			</Container>
		</Fragment>
	);
};

export default EnlessOffer;
