import { PREFIX } from "./helper";
import service from "services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { StickyDIPResponse } from "interfaces/sticky-dip";

export const getLimitedDeals = createAsyncThunk(
	`${PREFIX}/get-limited-deals`,
	async (): Promise<StickyDIPResponse | undefined> => {
		try {
			return await service.Get<StickyDIPResponse>(
				"/rest/offers?offerTypes=singleItem&sectionName=limited_deals"
			);
		} catch (error) {
			console.log(error);
		}
	}
);

export const clearUserStickyDip = createAsyncThunk(
	`${PREFIX}/get-limited-deals`,
	async (): Promise<void> => {
		try {
			await service.Post("/rest/debug/sticky-dips/trackers/delete");
		} catch (error) {
			console.log(error);
		}
	}
);
