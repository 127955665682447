import { IOffer, ITierStatus, WaterFallCard } from "./lib/WaterFallCard";
import {
	BgBottomBrush,
	BgTopBrush,
	Container,
	Image,
	OffersContainer,
	Title,
	Wrapper
} from "./styles";
import { useEffect, useState } from "react";
import GreenArrow from "../../../../assets/waterfall-sale/green_arrow.svg";
import PlusIcon from "../../../../assets/waterfall-sale/plus_icon.svg";
import { useWebStoreContext } from "contexts";
import { useGetOffersQuery, useGetFeatureTrackerQuery } from "redux/apis";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectDialogState, setWaterfallResponse } from "redux/slices";
import CountdownV2 from "components/CountdownV2";
import SectionHeader from "components/Section/SectionHeader";
import { LimitedDealsIcon } from "utils/svgIcons";
import { PageContent } from "components/PageContent";
import { timerStyle } from "./styles";

export const WaterFallSale = () => {
	const dispatch = useAppDispatch();
	const { nowTime, authToken } = useWebStoreContext();
	const { props } = useAppSelector(selectDialogState);
	const [refetchOnFocus, setRefetchOnFocus] = useState(true);
	const { isLoading: isPurchaseOffersLoading, data: purchaseOffers } =
		useGetOffersQuery({
			offerType: "singleItem",
			isAuthenticated: !!authToken?.value
		});

	const {
		isLoading: isSessionTrackerLoading,
		data,
		isError,
		refetch
	} = useGetFeatureTrackerQuery(
		{
			FeatureType: "waterfall-sale",
			isAuthenticated: !!authToken?.value
		},
		{ refetchOnFocus }
	);

	const getOfferbyId = (offerId: string) => {
		return purchaseOffers?.offers.find(
			(offer: IOffer) => offer?.id === offerId
		);
	};

	const handleEnd = () => {
		setTimeout(() => {
			refetch();
		}, 1000);
	};

	const handleCompleted = () => {
		setTimeout(() => {
			refetch();
		}, 5000);
	};

	useEffect(() => {
		if (data?.sessionTracker?.complete) {
			setRefetchOnFocus(false);
		} else {
			setRefetchOnFocus(true);
		}
	}, [data?.sessionTracker]);

	useEffect(() => {
		if (data?.sessionTracker?.complete && !props) {
			handleCompleted();
		}
	}, [data?.sessionTracker, props]);

	useEffect(() => {
		if (data?.success) {
			dispatch(setWaterfallResponse(data));
		}
	}, [data]);

	if (
		isPurchaseOffersLoading ||
		isSessionTrackerLoading ||
		isError ||
		!data?.sessionTracker
	) {
		return <></>;
	}

	const { sessionTracker } = data;

	return (
		<>
			<PageContent source={"store_page"} sxContainer={{ paddingBottom: 0 }}>
				<SectionHeader
					text="LIMITED DEALS"
					icon={<LimitedDealsIcon />}
					sx={{ marginBottom: { xs: "0px", sm: "10px" } }}
				/>
			</PageContent>
			<Container
				BackgroundImage={sessionTracker?.backgroundImageUrl}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
				}}
			>
				<BgTopBrush />
				<CountdownV2
					text={"Offer ends in:"}
					startTime={nowTime}
					endTime={sessionTracker?.endTime}
					onEnd={handleEnd}
					className={"centeredTimer"}
					style={{ ...timerStyle }}
				/>
				<Title BackgroundImage={sessionTracker?.titleImageUrl}></Title>
				<OffersContainer>
					{sessionTracker.tierStatuses?.map(
						(tierStatus: ITierStatus, index: number) => (
							<Wrapper key={index}>
								<WaterFallCard
									saleId={sessionTracker?.saleId}
									tierStatuses={sessionTracker.tierStatuses}
									tierStatus={tierStatus}
									offer={getOfferbyId(tierStatus?.tier?.offerId)}
								/>
								{index < sessionTracker.tierStatuses?.length - 1 && (
									<Image
										src={
											sessionTracker.tierStatuses[index + 1]?.tier?.offerId
												? GreenArrow
												: PlusIcon
										}
									/>
								)}
							</Wrapper>
						)
					)}
				</OffersContainer>
				<BgBottomBrush />
			</Container>
		</>
	);
};
